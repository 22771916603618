import { env } from '@/util/env.utils';
import { isSSR } from '@/util/isSSR';
import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import type { NextRequest } from 'next/server';
import { getNewApolloCache } from './cache';
import { authLink, splitLink } from './httpLink';

export interface GetNewApolloClientProps {
  req?: NextRequest;
  ssr?: boolean;
}

export const apolloCache = getNewApolloCache();

export const getNewApolloClient = ({ ssr, req }: GetNewApolloClientProps) => {
  if (ssr && isSSR()) {
    // Allow for internal cluster API calls if setup
    const apiDomain = env('NEXT_PUBLIC_API_INTERNAL')
      ? `http://${env('NEXT_PUBLIC_API_INTERNAL')}`
      : `${env('NEXT_PUBLIC_API_PROTOCOL')}://${env('NEXT_PUBLIC_API_DOMAIN')}`;

    return new ApolloClient({
      ssrMode: true,
      link: from([
        new RetryLink({ attempts: { max: 3 } }),
        createHttpLink({
          uri: `${apiDomain}/graphql`,
          fetchOptions: {},
          headers: {
            Cookie: req.headers.get('Cookie'),
          },
        }),
      ]),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
        watchQuery: {
          errorPolicy: 'all',
        },
      },
    });
  }

  return new ApolloClient({
    link: authLink.concat(splitLink),
    cache: apolloCache,
    connectToDevTools: true,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  });
};

export const apolloClientSideClient = getNewApolloClient({ ssr: false });
