import { useCallback, useEffect, useMemo, useState } from 'react';

interface StatusConfig {
  color: string;
  text: string;
  shouldAnimate: boolean;
}

type StatusReturn = [StatusConfig, (status: string) => void];

export default function useStatusConfig (status, isOwner): StatusReturn {
  const [config, setConfig] = useState({
    color: '',
    text: '',
    shouldAnimate: false,
  });

  const statusMap = useMemo(() => ({
    staged: {
      color: 'bg-gray-400',
      text: isOwner ?
        'You\'ve updated this, but the change hasn\'t been saved yet.' :
        'You\'ve updated this, but the change hasn\'t been saved yet. A change request will be sent to the owner when you hit save.',
    },
    'staged-non-approve': {
      color: 'bg-gray-400',
      text: 'You\'ve updated this, but the change hasn\'t been saved yet.',
    },
    'waiting for approval': {
      color: 'bg-[#FEDB39]',
      text: 'Change waiting for approval.',
    },
    'for review': {
      color: 'bg-[#FD9445]',
      text: 'A change has been submitted for your review. Approve or deny the change on the approvals page.',
    },
    'change denied': {
      color: 'bg-[#ED3641]',
      text: 'The change has been denied by the object owner.',
    },
    'change approved': {
      color: 'bg-[#8FF02E]',
      text: 'The change has been approved by the object owner.',
    },
    'recently changed': {
      color: 'bg-[#32ACF9]',
      text: 'Recently changed.',
      shouldAnimate: true,
    },
  }), [isOwner]);

  const handleSetConfig = useCallback((status) => {
    if (status in statusMap) {
      setConfig(statusMap[status]);
    }
  }, [statusMap]);

  useEffect(() => {
    handleSetConfig(status);
  }, [status, handleSetConfig]);

  return [config, handleSetConfig];
}
