import { markModularObjectUnread } from '@/apollo/reactiveVars/operations/mutations/unreadModularObjects';
import { GetHistoryUpdatesForObjectIdDocument } from '@/components/cards/UpdatesColumn/getHistoryUpdatesForObjectId.generated';
import { GetModularObjectHistoryByIdDocument } from '@/components/cards/UpdatesColumn/getModularObjectDiffs.generated';
import {
  buildUpdateObjectDiffPayloadForAttributes,
  flattenModuleLeaves,
} from '@/components/common/ModularObject/utils';
import { usePrismaticContext } from '@/components/Contexts/prismatic.context';
import { useUpdateAttributeMutation } from '@/components/modules/UpdateAttribute.generated';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import {
  GetTemplatesUsedByModularObjectsDocument,
} from '@/graphql/sideNavigation/getTemplatesUsedByModularObjects.generated';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { buildFieldsPendingApprovalMap } from '@/util/approvals';
import { debounce } from 'lodash';
import { useCallback, useMemo } from 'react';

interface UpdateValueArgs {
  newFieldValue: string;
  attributeName: string;
}

export const useUpdateAttributeValue = () => {
  const [updateAttribute] = useUpdateAttributeMutation();
  const { objectCardData } = useObjectCardContext();
  const { canDeleteJiraTask, updateJiraTicket } = usePrismaticContext();

  const flattenedModules = useMemo(() => {
    return flattenModuleLeaves(objectCardData?.template?.modules || {});
  }, [objectCardData]);

  const updateValue = useCallback(
    async ({ newFieldValue, attributeName }: UpdateValueArgs) => {
      const fieldId = attributeName.split('.').slice(-2).shift();
      const fieldName = flattenedModules[fieldId]?.name;
      const updateNamePayload = buildUpdateObjectDiffPayloadForAttributes({
        objectId: objectCardData?.id,
        fieldId,
        fieldName,
        newFieldValue,
      });

      await updateAttribute({
        variables: {
          input: {
            diffs: [updateNamePayload],
          },
        },
        refetchQueries: [
          GetModularObjectHistoryByIdDocument,
          GetHistoryUpdatesForObjectIdDocument,
          GetTemplatesUsedByModularObjectsDocument,
        ],
        onCompleted: (data) => {
          const fieldsPendingApprovalMap = buildFieldsPendingApprovalMap(data.updateModularObject?.[0]?.approvals);
          const isSaved = !fieldsPendingApprovalMap[fieldName];

          if (isSaved) {
            markModularObjectUnread(objectCardData?.id);
          }

          const SUCCESS_MESSAGE = isSaved
            ? 'Successfully saved new attribute'
            : 'Successfully requested approval for attribute update';

          addToastSuccess(SUCCESS_MESSAGE);
        },
        onError: () => addToastError('Failed to update attribute, please try again'),
      });

      if (canDeleteJiraTask) {
        await updateJiraTicket();
      }
    },
    [canDeleteJiraTask, flattenedModules, objectCardData?.id, updateAttribute, updateJiraTicket],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateValue = useCallback(
    debounce(async (value: UpdateValueArgs) => {
      await updateValue(value);
    }, 2000),
    [updateValue],
  );

  return useMemo(() => {
    return { debouncedUpdateValue, updateValue };
  }, [debouncedUpdateValue, updateValue]);
};
