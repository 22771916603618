import type { Dependency, DependencyBlockType } from '@/__generated__/types';
import { type GetGapDataQuery } from '../screens/getGapData.generated';

export default function getPostDependenciesInput(
    gapDataResponse: GetGapDataQuery,
    selectedGapType: DependencyBlockType,
    gapDays: number,
    isAddingMultipleDependencies: boolean,
) {
    const { getDependencyGapCalcs } = gapDataResponse ?? {};

    // When adding multiple dependencies, the user cannot set the gap
    if (isAddingMultipleDependencies) {
        return getDependencyGapCalcs?.map((dependency) => {
            const isNoOverlap = selectedGapType === 'noOverlap';
            const gapTypeVals = isNoOverlap
                ? dependency?.noOverlap
                : dependency?.strict;

            const correctedGapDays = isNoOverlap && gapTypeVals.calculatedGapDays < 0
                ? 0
                : gapTypeVals.calculatedGapDays;

            return {
                calculatedGapDays: correctedGapDays,
                modularObjectId: dependency.modularObjectId,
                blockedById: dependency.blockedById,
                blockType: selectedGapType,
            };
        }) as Partial<Dependency[]>;
    }

    const [{ modularObjectId, blockedById }] = getDependencyGapCalcs ?? [];

    return [{
        calculatedGapDays: +gapDays,
        modularObjectId,
        blockedById,
        blockType: selectedGapType,
    }] as Partial<Dependency[]>;
}