import cx from 'classnames';
import { type ComponentProps } from 'react';
import handleChange from './handleChange';
import isChecked from './isChecked';

export interface RadioInputProps extends ComponentProps<'input'> {
  name: string;
  value: string;
  label?: string | React.ReactNode;
  className?: string;
  labelClassName?: string;
  containerClassName?: string;
  checkedValue: string;
  onCheckedCallback: (value: string) => void;
}

export default function RadioInput ({
  name,
  label,
  value,
  checkedValue,
  onCheckedCallback,
  className,
  labelClassName,
  containerClassName,
  ...otherProps
}: RadioInputProps): JSX.Element {
  return (
    <div className={cx('flex items-center h-input', containerClassName)}>
      <input
        name={name}
        id={value}
        data-testid='radio-input'
        className={`w-[25px] h-[25px] shrink-0 ${className}`}
        type='radio'
        checked={isChecked(checkedValue, value)}
        value={value}
        onChange={handleChange(onCheckedCallback)}
        {...otherProps}
      />
      {label && (
        <label
          htmlFor={value}
          data-testid='radio-label'
          className={labelClassName ?? 'ml-2 font-medium effra-14 whitespace-nowrap truncate'}
        >
          {label}
        </label>
      )}
    </div>
  );
}
