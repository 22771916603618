import { type User } from '@/__generated__/types';
import { Input } from '@/components/form/InputDeprecated';
import ObjectParent from '@/components/ObjectParent';
import {
  UserPermissionOnObject,
} from '@/components/Schedule/Builds/BuildListItem/buildListItems/UserPermissionOnObject';
import DisabledObjectMessage from '@/components/tooltip/DisabledObjectMessage';
import Popover from '@/components/tooltip/Popover';
import UserComponent from '@/components/User/User';
import getIcon from '@/util/getIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/sharp-solid-svg-icons';
import classNames from 'classnames';
import type { ObjectListRowPropsFragment } from './ObjectListRowProps.generated';

export interface ObjectListRowProps {
  object: ObjectListRowPropsFragment;
  onChange: (event) => void;
  isSelected: boolean;
  isDisabled?: boolean;
}

export default function ObjectListRow ({
  object,
  onChange,
  isSelected,
  isDisabled,
}: Readonly<ObjectListRowProps>): JSX.Element {
  const inputName = `selectedObjects[${object.id}]`;

  return (
    <Popover
      popoverContent={object.deactivated
        && <DisabledObjectMessage objectId={object.id} organizationId={object.owner?.organizationId} />}
      placement='bottom'
      xOffset={-60}
    >
      <div className={classNames({ 'opacity-40': object.deactivated })}>
        <label htmlFor={inputName} className='flex flex-row'>
          <div className='flex flex-row items-center min-w-0 flex-[2] gap-[8px]'>
            <div className='flex min-w-0 flex-[1] gap-[8px]'>
              <Input
                name='selectedObjects'
                value={object.id}
                className={`shrink input-checkbox custom-checkbox checked:bg-primary`}
                type='checkbox'
                id={inputName}
                onChange={onChange}
                isChecked={isSelected}
                dataTestId='add-new-object-list-row-checkbox'
                disabled={object.deactivated || isDisabled}
              />
              {getIcon(object.template.name?.toLowerCase(), 'w-[20px] shrink')}
              <div
                className='flex-1 min-w-0 font-medium font-effra text-[14px] leading-[20px] truncate'
                title={object.name}
              >
                <span className='align-middle'>{object.name}</span>{' '}
                {(object.startDateLocked || object.targetDateLocked) && (
                  <FontAwesomeIcon icon={faLock} className='text-[8px] align-middle' />
                )}
              </div>
            </div>
            <div className='flex items-center min-w-0 flex-[0.75]'>
              <ObjectParent object={object.parent} />
            </div>
          </div>
          <div className='flex flex-1 min-w-0 gap-[8px]'>
            <div className='flex flex-[0.5] items-center leading-[16px]'>
              <UserPermissionOnObject modularObject={object} isBlackFont />
            </div>
            <div className='flex items-center min-w-0 flex-[0.15] gap-[8px]'>
              <UserComponent user={object.owner as Partial<User>} noRole={true} imageOnly />
            </div>
          </div>
        </label>
      </div>
    </Popover>
  );
}
