import type { Diff } from '@/__generated__/types';
import { markModularObjectUnread } from '@/apollo/reactiveVars/operations/mutations/unreadModularObjects';
import Spinner from '@/components/Icons/Spinner';
import { Button } from '@/designSystemComponents/Button';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { titleCase } from '@/util/functions';
import { patchModularObject } from '@/util/requests.functions';
import { useApolloClient } from '@apollo/client';
import { useState } from 'react';

interface UpdateResourceCostModalProps {
  selectedItems: string[];
  closeModal: () => void;
}

export default function UpdateResourceCostModal ({
  selectedItems,
  closeModal,
}: UpdateResourceCostModalProps): JSX.Element {
  const { reFetchObservableQueries } = useApolloClient();

  const loggedInUser = useLoggedInUser();
  const resourceCostUnit = loggedInUser?.organization?.resourceCostUnit || 'resource cost';

  const [newResourceCost, setNewResourceCost] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCancel = (): void => {
    closeModal();
  };

  const handleResourceCostInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewResourceCost(event.target.value);
  };

  const handleUpdateResourceCost = async (): Promise<Promise<void>> => {
    setIsSubmitting(true);
    // Form diffs for updating all selected drivers with new resource cost
    const driverDiffs: Array<Partial<Diff>> = selectedItems.map((driverID) => ({
      externalID: driverID,
      externalType: 'modular_object',
      diff: {
        displayNames: {
          resourceCost: titleCase(resourceCostUnit),
        },
        to: {
          resourceCost: newResourceCost,
        },
        from: null,
      },
    }));

      patchModularObject(driverDiffs)
        .then(async () => {
          selectedItems.forEach((driverID) => {
            markModularObjectUnread(driverID);
          });
          closeModal();
          await reFetchObservableQueries();
        })
        .catch((e) => {
          console.error(e);
        });
  };

  return (
    <div className='flex flex-col flex-1 gap-6 p-3'>
      <div className='effra-16'>Update {resourceCostUnit}</div>
      <div className=''>
        <div className='label'>{resourceCostUnit}</div>
        <input
          className='w-2/3 input-text'
          placeholder={`Enter ${resourceCostUnit}`}
          type='number'
          step={1}
          onChange={handleResourceCostInputChange}
        />
      </div>
      <div className='flex gap-4 justify-between pt-3'>
        <Button figmaProps={{ style: 'outline', size: 'large' }} onClick={handleCancel}>Cancel</Button>
        <Button figmaProps={{ style: 'fill', size: 'large' }} onClick={handleUpdateResourceCost}>
          {isSubmitting
            ? (
              <div className='flex flex-col items-center grow'>
                <div className='flex gap-2 items-center'>
                  <Spinner className='w-6 h-6 animate-spin' /> Updating
                </div>
              </div>
            )
            : 'Update'}
        </Button>
      </div>
    </div>
  );
}
