import type { DependencyBlockType } from '@/__generated__/types';
import RadioInput from '@/components/form/RadioInput/RadioInput';
import { useLoggedInSubscription } from '@/hooks/useLoggedInUser';
import cx from 'classnames';
import { useMemo } from 'react';
import DependencyTypeSelectOptionLabel from './DependencyTypeSelectOptionLabel';

export interface DependencyTypeSelectOptionProps {
  type: DependencyBlockType;
  checkedValue: DependencyBlockType;
  onChecked: (type: DependencyBlockType) => void;
}

export default function DependencyTypeSelectOption (
  { type, checkedValue, onChecked }: DependencyTypeSelectOptionProps,
): JSX.Element {
  const subscription = useLoggedInSubscription();

  // Determine if the feature is restricted based on the user's subscription
  const isRestricted = useMemo(() =>
    subscription?.featureLimits && (
      type === 'strict' && 'DEPENDENCIES_FIXED' in subscription.featureLimits ||
      type === 'noOverlap' && 'DEPENDENCIES_NO_OVERLAP' in subscription.featureLimits
    ), [type, subscription]);

  return (
    <div
      className={cx('flex w-full', {
        'cursor-not-allowed': isRestricted,
        'text-gray-30': isRestricted,
      })}
    >
      <RadioInput
        name='dependency-type'
        value={type}
        checkedValue={checkedValue}
        onCheckedCallback={onChecked}
        className={cx({
          'cursor-not-allowed': isRestricted,
        })}
        label={<DependencyTypeSelectOptionLabel type={type} isRestricted={isRestricted} />}
        labelClassName={cx('overflow-visible effra-14 font-bold w-full', {
          'text-gray-30': isRestricted,
          'cursor-not-allowed': isRestricted,
        })}
        containerClassName={cx('gap-[8px] w-full', {
          'cursor-not-allowed': isRestricted,
        })}
        disabled={isRestricted}
      />
    </div>
  );
}
