import type { DependencyBlockType } from '@/__generated__/types';
import ProBadge from '@/designSystemComponents/Badge/ProBadge';
import cx from 'classnames';
import { useMemo } from 'react';
import DependencyToolTip from '../../DependencyToolTip/DependencyToolTip';

export interface DependencyTypeSelectOptionLabelProps {
  type: DependencyBlockType;
  isRestricted: boolean;
}

export default function DependencyTypeSelectOptionLabel ({
  type,
  isRestricted,
}: DependencyTypeSelectOptionLabelProps): JSX.Element {
  // Get the label, icon, and whether the feature is pro-restricted based on the provided type
  const { label, icon, isProFeature } = useMemo(() => {
    return {
      none: {
        label: 'None',
        icon: (
          <i
            className={cx('fa-kit fa-slack-shift text-black', {
              'text-gray-30': isRestricted,
            })}
          />
        ),
        isProFeature: false,
      },
      strict: {
        label: 'Fixed',
        icon: (
          <i
            className={cx('fa-kit fa-slack-shift text-black', {
              'text-gray-30': isRestricted,
            })}
          />
        ),
        isProFeature: true,
      },
      'noOverlap': {
        label: 'No Overlap',
        icon: (
          <i
            className={cx('fa-kit fa-slack-shift text-black', {
              'text-gray-30': isRestricted,
            })}
          />
        ),
        isProFeature: true,
      },
    }[type];
  }, [type, isRestricted]);

  return (
    <span data-testid='dependency-type-select-option-label' className='flex items-center justify-between'>
      <div className='flex items-center gap-2'>
        {icon}
        <span className='truncate'>{label}</span>
        {isProFeature && isRestricted && (
          <div className='relative h-full flex items-center'>
            <div className='absolute z-[5]'>
              <ProBadge shouldExpand />
            </div>
          </div>
        )}
      </div>

      <DependencyToolTip
        type={type}
        side='left'
        className={cx({
          'translate-y-[-40px]': type === 'noOverlap',
          'translate-y-[40px]': type === 'none',
        })}
      />
    </span>
  );
}
