import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type DismissOverlapConversionAlertMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DismissOverlapConversionAlertMutation = { __typename?: 'Mutation', dismissDependencyOverlapConversion: Array<{ __typename?: 'DependencyOverlapConversion', id: string | null, dismissedByUser: boolean | null, objectName: string | null }> };


export const DismissOverlapConversionAlertDocument = gql`
    mutation DismissOverlapConversionAlert {
  dismissDependencyOverlapConversion {
    id
    dismissedByUser
    objectName
  }
}
    `;
export type DismissOverlapConversionAlertMutationFn = Apollo.MutationFunction<DismissOverlapConversionAlertMutation, DismissOverlapConversionAlertMutationVariables>;

/**
 * __useDismissOverlapConversionAlertMutation__
 *
 * To run a mutation, you first call `useDismissOverlapConversionAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissOverlapConversionAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissOverlapConversionAlertMutation, { data, loading, error }] = useDismissOverlapConversionAlertMutation({
 *   variables: {
 *   },
 * });
 */
export function useDismissOverlapConversionAlertMutation(baseOptions?: Apollo.MutationHookOptions<DismissOverlapConversionAlertMutation, DismissOverlapConversionAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DismissOverlapConversionAlertMutation, DismissOverlapConversionAlertMutationVariables>(DismissOverlapConversionAlertDocument, options);
      }
export type DismissOverlapConversionAlertMutationHookResult = ReturnType<typeof useDismissOverlapConversionAlertMutation>;
export type DismissOverlapConversionAlertMutationResult = Apollo.MutationResult<DismissOverlapConversionAlertMutation>;
export type DismissOverlapConversionAlertMutationOptions = Apollo.BaseMutationOptions<DismissOverlapConversionAlertMutation, DismissOverlapConversionAlertMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDismissOverlapConversionAlertMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ dismissDependencyOverlapConversion })
 *   )
 * })
 */
export const mockDismissOverlapConversionAlertMutation = (resolver: Parameters<typeof graphql.mutation<DismissOverlapConversionAlertMutation, DismissOverlapConversionAlertMutationVariables>>[1]) =>
  graphql.mutation<DismissOverlapConversionAlertMutation, DismissOverlapConversionAlertMutationVariables>(
    'DismissOverlapConversionAlert',
    resolver
  )
