import type { ModularObject } from '@/__generated__/types';
import { Button } from '@/components/Button/Button';
import { useObjectCardScrollContext } from '@/components/common/ModularObject/Card/ObjectCardScroll.context';
import InfoTooltip from '@/components/tooltip/InfoTooltip';
import { useModal } from '@/hooks/useModal';
import { useObjectCardContext } from '@/state/ObjectCard.context';

import LoadingSpinner from '@/components/common/Drivers/DriverSection/LoadingSpinner';
import { Input } from '@/components/form/Input';
import MagnifyingGlassIcon from '@/components/Icons/MagnifyingGlassIcon';
import { useDebounce } from '@/hooks/useDebounce';
import cx from 'classnames';
import { type ChangeEvent, createRef, type Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ObjectListRow from '../AddNewObjectModal/ObjectListRow/ObjectListRow';
import CreateNewObjectModal, { Filter } from '../CreateNewObjectModal/CreateNewObjectModal';
import MegaModal from '../MegaModal/MegaModal';
import MegaModalEmptyList from '../MegaModal/MegaModalEmptyList/MegaModalEmptyList';
import MegaModalHeader from '../MegaModal/MegaModalHeader/MegaModalHeader';
import { useGetBulkAddToParentModalResultsQuery } from './GetBulkAddToParentModalResults.generated';

export interface AddToParentModalProps {
  modularObjects: ModularObject[];
  preselectedId?: string;
  onSubmit: (parentId: string) => void;
  disableCreateObject?: boolean;
  disableCreateObjectMessage?: string;
}

export default function AddToParentModal ({
  modularObjects,
  preselectedId = '',
  onSubmit,
  disableCreateObject = false,
  disableCreateObjectMessage,
}: AddToParentModalProps): JSX.Element {
  const inputRef: Ref<HTMLInputElement> = createRef();
  const tableRef = useRef<HTMLDivElement>(null);
  const { showModal } = useModal();
  const { scrollToId } = useObjectCardScrollContext();
  const { openViewObjectCardDrawer } = useObjectCardContext();

  const { data, loading: isLoading } = useGetBulkAddToParentModalResultsQuery({
    fetchPolicy: 'network-only',
    skip: !modularObjects.length,
    variables: { ids: modularObjects.map(({ id }) => id) },
  });

  const results = useMemo(() => data?.getPossibleParentsForModularObjects || [], [data]);

  const [searchTerm, setSearchTerm] = useState('');
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [selected, setSelected] = useState(preselectedId.length ? [preselectedId] : []);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    const tableWrapper = tableRef.current;
    if (tableWrapper?.scrollHeight > tableWrapper?.clientHeight) {
      setHasScrollbar(true);
    } else {
      setHasScrollbar(false);
    }
  }, []);

  useEffect(() => {
    inputRef?.current?.focus();
    setSearchTerm('');
  }, []);

  const resultList = useMemo(() => {
    if (!results) {
      return [];
    }

    if (preselectedId) {
      return results.sort((a, b) => (a.id === preselectedId) ? -1 : b.id === preselectedId ? 1 : 0);
    }

    return results;
  }, [results, preselectedId]);

  const filteredResultList = useMemo(() => {
    return resultList.filter((result) => {
      return result.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
        result.parent?.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
    });
  }, [resultList, debouncedSearchTerm]);

  const handleCreateNew = useCallback(() => {
    const modularObject = modularObjects[0];
    showModal(
      <CreateNewObjectModal
        createdFromId={modularObject.id}
        createdFromTemplateId={modularObject.templateId}
        asParent={true}
        filteredBy={Filter.Builds}
        afterObjectCreationSave={(newModularObjectParent) => {
          showModal(
            <AddToParentModal
              modularObjects={[modularObject]}
              preselectedId={newModularObjectParent.id}
              onSubmit={onSubmit}
            />,
            {
              isMegaModal: true,
              showCloseIcon: false,
            },
          );

          openViewObjectCardDrawer({
            modularObjectId: modularObject.id,
          });
        }}
      />,
      {
        isMegaModal: true,
        showCloseIcon: false,
      },
    );
  }, [modularObjects, onSubmit, openViewObjectCardDrawer, showModal]);

  const handleAddClick = useCallback(async () => {
    scrollToId('details-section');
    onSubmit(selected[0]);
  }, [onSubmit, scrollToId, selected]);

  const handleSelection = useCallback((e) => {
    if (e.target.checked) {
      setSelected([e.target.value]);

      return;
    }

    setSelected([]);
  }, []);

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const AddObjectButton = useMemo(() => {
    return (
      <Button
        className={cx('grow h-[unset] w-[unset] px-4 py-3 btn-ghost')}
        disabled={disableCreateObject}
        onClick={handleCreateNew}
      >
        NEW ITEM
      </Button>
    );
  }, [disableCreateObject, handleCreateNew]);

  // Keeps a flicker of "no results" content showing while the data fetch happens
  if (isLoading) {
    return (
      <MegaModal className='flex justify-center items-center w-screen gap-[24px] h-[80vh]'>
        <LoadingSpinner isLoading={true} />
      </MegaModal>
    );
  }

  return (
    <MegaModal className='w-screen gap-[24px] h-[80vh]'>
      <MegaModalHeader title='Select Parent Item' />
      <div className='flex flex-col min-h-0 gap-[24px] grow'>
        <div className='flex gap-4 pb-1 border-b border-black border-solid shrink'>
          <div className='flex items-center'>
            <MagnifyingGlassIcon />
          </div>
          <div className='grow'>
            <Input
              className='flex-1 text-lg placeholder-gray-400 text-black outline-none'
              ref={inputRef}
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder='Search'
            />
          </div>
        </div>

        <div className='flex flex-col gap-2 min-h-0 grow'>
          <div
            className={cx('flex flex-row', {
              'pr-5': hasScrollbar,
            })}
          >
            {!filteredResultList.length
              ? <div className='text-xs tracking-widest text-gray-400 uppercase'>Results</div>
              : (
                <>
                  <div className='flex flex-row items-center min-w-0 flex-[2] gap-[8px]'>
                    <div className='flex flex-[2] gap-[8px]'>
                      <div className='flex-1 font-bold leading-[16px]'>Name</div>
                      <div className='invisible w-[18px]' />
                      <div className='invisible w-[20px]' />
                    </div>
                    <div className='flex-1 font-bold leading-[16px]'>Item Type</div>
                    <div className='font-bold flex-[2] leading-[16px]'>Parent</div>
                  </div>
                  <div className='flex flex-1 min-w-0 gap-[8px]'>
                    <div className='flex-1 font-bold leading-[16px]'>Permission</div>
                    <div className='font-bold flex-[2] leading-[16px]'>Owner</div>
                    <div className='flex-1 font-bold leading-[16px]'>Company</div>
                  </div>
                </>
              )}
          </div>

          <div className='flex overflow-y-auto flex-col flex-1' ref={tableRef}>
            {!filteredResultList.length
              ? (
                <MegaModalEmptyList
                  message='No builds match your search.'
                  buttonDisabled={disableCreateObject}
                  buttonText='Create this Build'
                  buttonToolTip={disableCreateObject ? disableCreateObjectMessage : null}
                  onClick={handleCreateNew}
                />
              )
              : (
                <div className='flex flex-col gap-[16px] no-scrollbar'>
                  {filteredResultList.map((result, index) => {
                    const isSelected = selected.includes(result.id);
                    return (
                      <ObjectListRow
                        key={`add-new-object-list-row-${index}`}
                        object={result}
                        onChange={handleSelection}
                        isSelected={isSelected}
                      />
                    );
                  })}
                </div>
              )}
          </div>
        </div>
      </div>
      <div className='flex flex-row gap-[32px]'>
        {modularObjects?.length === 1 && (
          <>
            {disableCreateObjectMessage
              ? <InfoTooltip message={disableCreateObjectMessage} className='flex grow'>{AddObjectButton}</InfoTooltip>
              : AddObjectButton}
          </>
        )}

        <Button
          className={cx('grow h-[unset] w-[unset] px-4 py-3 btn-primary')}
          onClick={handleAddClick}
          disabled={!selected.length}
        >
          ADD ITEM(S)
        </Button>
      </div>
    </MegaModal>
  );
}
