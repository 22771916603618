import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetProgramsQueryVariables = Types.Exact<{
  paginationInput: Types.OffsetPaginationInput;
}>;


export type GetProgramsQuery = { __typename?: 'Query', getPrograms: { __typename?: 'PaginatedModularObjects', edges: Array<{ __typename?: 'ModularObject', id: string, name: string | null, template: { __typename?: 'Template', name: string | null } | null } | null> | null, pageInfo: { __typename?: 'OffsetPageInfo', hasNextPage: boolean | null } | null } };


export const GetProgramsDocument = gql`
    query GetPrograms($paginationInput: OffsetPaginationInput!) {
  getPrograms(paginationInput: $paginationInput) {
    edges {
      id
      name
      template {
        name
      }
    }
    pageInfo {
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetProgramsQuery__
 *
 * To run a query within a React component, call `useGetProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramsQuery({
 *   variables: {
 *      paginationInput: // value for 'paginationInput'
 *   },
 * });
 */
export function useGetProgramsQuery(baseOptions: Apollo.QueryHookOptions<GetProgramsQuery, GetProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProgramsQuery, GetProgramsQueryVariables>(GetProgramsDocument, options);
      }
export function useGetProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProgramsQuery, GetProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProgramsQuery, GetProgramsQueryVariables>(GetProgramsDocument, options);
        }
export function useGetProgramsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProgramsQuery, GetProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProgramsQuery, GetProgramsQueryVariables>(GetProgramsDocument, options);
        }
export type GetProgramsQueryHookResult = ReturnType<typeof useGetProgramsQuery>;
export type GetProgramsLazyQueryHookResult = ReturnType<typeof useGetProgramsLazyQuery>;
export type GetProgramsSuspenseQueryHookResult = ReturnType<typeof useGetProgramsSuspenseQuery>;
export type GetProgramsQueryResult = Apollo.QueryResult<GetProgramsQuery, GetProgramsQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetProgramsQuery((req, res, ctx) => {
 *   const { paginationInput } = req.variables;
 *   return res(
 *     ctx.data({ getPrograms })
 *   )
 * })
 */
export const mockGetProgramsQuery = (resolver: Parameters<typeof graphql.query<GetProgramsQuery, GetProgramsQueryVariables>>[1]) =>
  graphql.query<GetProgramsQuery, GetProgramsQueryVariables>(
    'GetPrograms',
    resolver
  )
