import { EditContextProvider, ModalProvider } from '@/components';
import { AppWrapper } from '@/state/app.context';
import { makeStore } from '@/state/store';
import { ApolloProvider } from '@apollo/client';
import { type AppProps } from 'next/app';
import Head from 'next/head';
import { type PropsWithChildren, useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import '@/styles/global.css';
import '@/components/form/datePicker/datePicker.css';
import ErrorBoundary from '@/components/ErrorBoundary';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { config } from '@fortawesome/fontawesome-svg-core';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import '@fortawesome/fontawesome-svg-core/styles.css';
import '@/util/toCamelCase';
import { apolloClientSideClient } from '@/apollo/apolloClient';
import ObjectCardScrollProvider from '@/components/common/ModularObject/Card/ObjectCardScroll.context';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { ObjectCardContextProvider } from '@/state/ObjectCard.context';
import useInitDataDog from '@/util/datadog';
import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';
import prismatic from '@prismatic-io/embedded';
import NoOverlapChangeAlert from '@/components/modals/NoOverlapChangeAlert/NoOverlapChangeAlert';

const reduxStore = makeStore();

config.autoAddCss = false;

interface ApplicationProps extends AppProps {
  environmentVariables: Record<string, string>;
}

function Application(
  { Component, ...props }: ApplicationProps,
): JSX.Element {
  useEffect(() => {
    datadogLogs.logger.info('Client Application Started');
    prismatic.init();

    document.addEventListener('mousedown', disableTextSelection);
    document.addEventListener('mouseup', disableTextSelection);

    return () => {
      document.removeEventListener('mousedown', disableTextSelection);
    };
  }, []);

  // Prevents text from being highlighted when shift or ctrl is held down
  // This is to prevent the browser from selecting text when the user is trying to select multiple objects with checkboxes
  const disableTextSelection = (event: MouseEvent) => {
    if (event.shiftKey || event.ctrlKey) {
      event.preventDefault();
    }
  };

  return (
    <ErrorBoundary>
      <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID} />
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID} />
      <Head>
        <meta name='viewport' content='width=device-width,initial-scale=1.0' />
        <title>Integrate</title>
      </Head>
      <ApolloProvider client={apolloClientSideClient}>
        <AppWithApollo>
          <Component {...props.pageProps} />
        </AppWithApollo>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

function AppWithApollo({ children }: PropsWithChildren): JSX.Element {
  const loggedInUser = useLoggedInUser();
  useInitDataDog({ user: loggedInUser });

  return (
    <ObjectCardContextProvider>
      <ReduxProvider store={reduxStore}>
        <EditContextProvider canUserEdit={true}>
          <ObjectCardScrollProvider>
            <ModalProvider>
              <AppWrapper>
                <NoOverlapChangeAlert />
                {children}
              </AppWrapper>
            </ModalProvider>
          </ObjectCardScrollProvider>
        </EditContextProvider>
      </ReduxProvider>
    </ObjectCardContextProvider>
  );
}

export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
  options: {
    inspectors: [
      {
        type: 'flag-used',
        name: 'dd-inspector',
        method: (key, detail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value);
        },
      },
    ],
  },
})(Application);
