import { useEffect } from 'react';

interface DurationInputProps {
  duration: string;
  setDuration: (duration: string) => void;
  setIsFocused: (isFocused: boolean) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  isLocked: boolean;
}

export default function DurationInput (
  { duration, setDuration, setIsFocused, inputRef, isLocked }: DurationInputProps,
) {
  useEffect(() => {
    if (inputRef.current) {
      const length = duration?.toString().length;
      const newWidth = Math.max(10, length * 8); // Minimum width of 50px

      inputRef.current.style.width = duration ? `${newWidth}px` : '100%';
    }
  }, [duration, inputRef]);

  return (
    <div className='flex items-center'>
      <input
        type='text'
        placeholder='Enter duration'
        ref={inputRef}
        value={duration}
        disabled={isLocked}
        className='h-full outline-none min-w-[1ch] placeholder-neutral-400'
        onChange={(e) => {
          const newValue = e.target.value;
          if (/^\d{0,6}$/.test(newValue)) {
            setDuration(newValue);
          }
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        maxLength={6}
      />
      {duration && <span className='ml-1 align-baseline mt-[2px]'>days</span>}
    </div>
  );
}
