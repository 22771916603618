import Color from '@/components/types/Color.enum';
import BaseBadge from '@/designSystemComponents/Badge/BaseBadge';
import { useModal } from '@/hooks/useModal';
import metrics from '@/util/metrics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMushroom } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState } from 'react';
import * as pricingAnimation from './data/data.json';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

interface ProBadgeProps {
  shouldExpand?: boolean;
}

export default function ProBadge ({ shouldExpand = false }: ProBadgeProps) {
  const router = useRouter();

  const { closeModal, isModalOpen } = useModal();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const gotoPlanPage = async () => {
    if (isModalOpen) {
      closeModal();
    }

    metrics.track('sidenav - nav to plan page via pro badge click');
    await router.push('/settings/plan');
  };

  return (
    <BaseBadge
      color={Color.PINK}
      className={cx(
        'flex flex-row h-[16px] opacity-100 group/pro-badge effra-12 font-normal normal-case tracking-normal items-center cursor-pointer overflow-visible w-[26px]',
        {
          'transition-all hover:w-[106px]': shouldExpand,
        },
      )}
      onClick={gotoPlanPage}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span
        className={cx('hidden whitespace-nowrap items-center transition relative', {
          'group-hover/pro-badge:visible group-hover/pro-badge:flex group-hover/pro-badge:gap-3': shouldExpand,
        })}
      >
        <FontAwesomeIcon
          icon={faMushroom}
          className='text-accent'
        />
        Upgrade to&nbsp;
        {/* Confetti */}
        {isHovered && (
          <div className='overflow-visible absolute top-0 right-0 translate-x-[-65px] translate-y-[-36px] scale-x-[-1]'>
            <Lottie animationData={pricingAnimation} loop={false} />
          </div>
        )}
      </span>
      Pro
    </BaseBadge>
  );
}
