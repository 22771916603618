import { Button } from '@/designSystemComponents/Button';

export interface DependencyConfigButtonsProps {
  isAddDisabled: boolean;
  isEditing: boolean;
  onBackClick: () => void;
  onAddClick: () => void;
}

export default function DependencyConfigButtons (
  { isAddDisabled, isEditing, onBackClick, onAddClick }: DependencyConfigButtonsProps,
) {
  return (
    <div className='flex justify-end gap-[32px]'>
      {!isEditing && (
        <Button
          figmaProps={{
            style: 'outline',
            size: 'small',
          }}
          onClick={onBackClick}
        >
          Back
        </Button>
      )}
      <Button
        figmaProps={{
          style: 'fill',
          size: 'small',
        }}
        onClick={onAddClick}
        disabled={isAddDisabled}
      >
        {isEditing ? 'Save Changes' : 'Add Dependency & Save'}
      </Button>
    </div>
  );
}
