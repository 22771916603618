import type { UpdateModularObjectInput } from '@/__generated__/types';
import { SelectAssigneeModal, useEditContext, User } from '@/components';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { useCallback, useMemo, useState } from 'react';
import { NIL as NIL_UUID } from 'uuid';
import { addToastError, addToastSuccess } from '../Toast/utils';
import { useGetUserByIdQuery, type UserByIdFragment } from '../User/getUserById.generated';
import { useGetUsersByIdQuery } from '../User/getUsersById.generated';
import HookFormLabel from './HookFormLabel';
import { useUpdateAssigneeMutation } from './UpdateAssignee.generated';
import { markModularObjectUnread } from '@/apollo/reactiveVars/operations/mutations/unreadModularObjects';

interface AssigneesProps {
  name?: string;
  className?: string;
  labelProps?: Record<string, unknown>;
}

export default function Assignee ({ name, className, labelProps = {} }: AssigneesProps): JSX.Element {
  const currentUser = useLoggedInUser();
  const { objectCardData } = useObjectCardContext();
  const currentAssigneeId = objectCardData?.assigneeId;
  const { canUserEdit } = useEditContext();
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState<boolean>(false);
  const [updateAssignee] = useUpdateAssigneeMutation();

  // Get the collaborator IDs from object shares
  const collaboratorIds = useMemo(() => objectCardData?.shares?.map(share => share.userId), [objectCardData]);

  // Check if the current assignee is a collaborator
  // We don't display the assignee if they aren't a collaborator
  // TODO: Clean this up on the backend so assignee is cleared if removed as a collaborator
  const isAssigneeCollaborator = useMemo(
    () => objectCardData?.ownerId === currentAssigneeId || collaboratorIds.includes(currentAssigneeId),
    [
      objectCardData?.ownerId,
      collaboratorIds,
      currentAssigneeId,
    ],
  );

  // Get the user for the current assignee ID
  const { data: assigneeData } = useGetUserByIdQuery({
    variables: {
      id: currentAssigneeId,
    },
    skip: !currentAssigneeId,
  });

  // Get the users for the collaborators
  const { data: collaboratorData } = useGetUsersByIdQuery({
    variables: {
      ids: collaboratorIds,
    },
    skip: !collaboratorIds?.length,
  });

  const isAssigneeDeletedUser = useMemo(() => Boolean(assigneeData?.getUserByID?.deletedAt), [assigneeData]);

  const handleOpenAssignModal = (): void => {
    setIsAssigneeModalOpen(true);
  };

  const handleCloseAssignModal = (): void => {
    setIsAssigneeModalOpen(false);
  };

  // Display the assignee if the user is a collaborator or the owner or if the user has been deleted
  const assignee = isAssigneeCollaborator || isAssigneeDeletedUser ? assigneeData?.getUserByID : null;
  const assigneeCandidates = useMemo(() => {
    const collaboratorUsers: Array<Partial<UserByIdFragment>> = collaboratorData?.getUsersByID ?? [];
    // Include the current user in the list of candidates if not already included
    if (!collaboratorIds?.includes(currentUser.id)) {
      return [currentUser, ...collaboratorUsers];
    }

    return collaboratorUsers;
  }, [collaboratorData, collaboratorIds, currentUser]);

  const handleUpdateAssignee = useCallback(async (assigneeId: string) => {
    const updateAssigneePayload: UpdateModularObjectInput = {
      diffs: [{
        diffType: 'updated',
        externalID: objectCardData?.id,
        externalType: 'modular_object',
        diff: { to: { [name]: assigneeId }, displayNames: { [name]: name } },
        fromMigration: false,
      }],
    };

    await updateAssignee({
      variables: {
        input: updateAssigneePayload,
      },
      onCompleted: () => {
        markModularObjectUnread(objectCardData?.id);
        addToastSuccess('Successfully updated assignee');
      },
      onError: () => {
        addToastError('Failed to update assignee');
      },
    });
    handleCloseAssignModal();
  }, [name, objectCardData?.id, updateAssignee]);

  return (
    <>
      <button
        data-testid='assignee'
        type='button'
        onClick={handleOpenAssignModal}
        className='flex gap-3 items-center group'
      >
        {Boolean(labelProps && Object.keys(labelProps)?.length) && (
          <div className={className}>
            {labelProps ? <HookFormLabel name={'assigneeId'} {...labelProps} /> : null}
          </div>
        )}
        <User
          user={assignee}
          disableUserProfileDrawer
          display='single'
          size='small'
          className='effra-14'
        />
        <div className='font-medium opacity-0 transition-all group-hover:opacity-100 text-primary text-[14px]'>
          {canUserEdit && (!assignee?.id || assignee?.id === NIL_UUID) ? 'Assign' : 'Reassign'}
        </div>
      </button>
      <SelectAssigneeModal
        currentAssigneeId={currentAssigneeId}
        collaborators={assigneeCandidates}
        isModalOpen={isAssigneeModalOpen}
        onClose={handleCloseAssignModal}
        onSubmit={handleUpdateAssignee}
      />
    </>
  );
}
