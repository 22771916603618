import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/sharp-regular-svg-icons';
import dynamic from 'next/dynamic';

import type { DependencyBlockType } from '@/__generated__/types';
import { useMemo } from 'react';
import * as fixedGapAnimation from './gapGifs/fixed.json';
import * as noOverlapAnimation from './gapGifs/no-overlap.json';
import * as noGapAnimation from './gapGifs/none.json';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

export default function DependencyToolTipPopover (
  { type = 'none' }: { type: DependencyBlockType },
): JSX.Element {
  const animation = useMemo(() => {
    switch (type) {
      case 'strict':
        return fixedGapAnimation;
      case 'noOverlap':
        return noOverlapAnimation;
      default:
        return noGapAnimation;
    }
  }, [type]);

  return (
    <div
      className='flex flex-col gap-[8px] p-[16px] shadow-[0_4px_8px_0px_rgba(0,0,0,0.16)] bg-white'
      data-testid='dependency-tooltip-popover'
    >
      <span className='font-normal effra-12 text-black'>
        Set the distance between your items&apos; target and start dates.
      </span>
      <Lottie animationData={animation} className='h-full' />
      <div className='flex gap-[8px] px-[6px] py-[4px] text-[#0D1815] bg-[#D9F8FF] rounded-[4px]'>
        <FontAwesomeIcon icon={faLightbulb} />
        <span className='effra-12'>
          Changes made to the gap will move Origin object&apos;s dates accordingly, and may affect other downstream
          dependencies.
        </span>
      </div>
    </div>
  );
}
