import { markModularObjectsUnread } from '@/apollo/reactiveVars/operations/mutations/unreadModularObjects';
import { addToastSuccess } from '@/components/Toast/utils';
import { Button } from '@/designSystemComponents/Button';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useShiftDatesMutation } from './shiftDates.generated';

interface ShiftDatesModalProps {
  closeModal: () => void;
  selectedItems: string[];
}

enum ShiftTarget {
  BOTH = 'BOTH',
  START_DATE = 'START_DATE',
  TARGET_DATE = 'TARGET_DATE',
}

const NUM_OBJECTS_TO_SHIFT_LIMIT = 250;

export default function ShiftDatesModal ({ closeModal, selectedItems }: ShiftDatesModalProps): JSX.Element {
  const [daysToShift, setDaysToShift] = useState<number | undefined>(undefined);
  const [shiftDates, { loading: isSubmittingDateShift, error: dateShiftError }] = useShiftDatesMutation();

  const shouldShowLimitWarning = (selectedItems?.length ?? 0) > NUM_OBJECTS_TO_SHIFT_LIMIT;

  const submit = async (): Promise<void> => {
    await shiftDates({
      variables: {
        input: {
          daysToShift,
          ids: selectedItems,
          shiftTarget: ShiftTarget.BOTH,
        },
      },
      onCompleted: (response) => {
        addToastSuccess('Dates updated successfully');
        const responseIds = response.shiftDates.map(({ id }) => id);
        markModularObjectsUnread(responseIds);
        closeModal();
      },
      onError: (error) => {
        console.error('[submit] error', error);
      },
    });
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='effra-20'>Shift Dates</div>
      <div className='flex flex-col gap-1'>
        <label htmlFor='daysToShift' className='font-medium text-gray-700'>
          Number of days to shift
        </label>
        <div className='flex gap-2 items-end'>
          <input
            id='daysToShift'
            type='number'
            className='border border-gray-300 rounded-sm px-3 py-2 focus:outline-none input'
            placeholder='Enter number of days'
            value={daysToShift || ''}
            onChange={e => setDaysToShift(Number(e.target.value))}
          />
          <div className='text-gray-500'>day(s)</div>
        </div>
        <p className='text-sm text-gray-500'>
          Enter a positive number to shift dates forward, or a negative number to shift dates backward
        </p>
      </div>
      <Button
        figmaProps={{
          style: 'fill',
          size: 'large',
        }}
        onClick={submit}
        disabled={!daysToShift || isSubmittingDateShift}
      >
        Submit
      </Button>
      {Boolean(dateShiftError) &&
        (
          <div className='text-warning effra-14'>
            <i className='fa-solid fa-triangle-exclamation' /> <span className='font-medium'>Error:</span>
            {dateShiftError?.message}
          </div>
        )}
      {shouldShowLimitWarning &&
        (
          <div className='text-warning effra-14'>
            <div className='font-medium'>
              <FontAwesomeIcon icon={faXmark} className='mr-2' /> Wow, that&apos;s a lot!
            </div>
            <div>
              You can&apos;t shift that many dates at once. Please select no more than {NUM_OBJECTS_TO_SHIFT_LIMIT}{' '}
              items at a time.
            </div>
          </div>
        )}
    </div>
  );
}
