import type { Dependency } from '@/__generated__/types';
import { useCardModal } from '@/app/components/hooks/useCardModal';
import Stepper, { Step } from '@/components/Stepper/Stepper';
import type { ModularObjectsState } from '@/state/slices/modularObjects.slice';
import metrics from '@/util/metrics';
import { useEffect, useRef, useState } from 'react';
import MegaModal from '../MegaModal/MegaModal';
import type { EditableDependency } from './screens/DependencyConfigScreen/DependencyConfigScreen';
import DependencyConfigScreen from './screens/DependencyConfigScreen/DependencyConfigScreen';
import DependencySelectionScreen from './screens/DependencySelectionScreen';

interface DependencyModalProps {
  readonly currentObjectId: string;
  readonly templateId?: string;
  readonly templateName?: string;
  readonly dependencyToEdit?: EditableDependency;
  readonly isBlockingDependency?: boolean;
  readonly initialStep?: number;
  readonly modularObjects?: ModularObjectsState;
  readonly blockedBy?: Dependency[];
  readonly blocking?: Dependency[];
}

export default function DependencyModal ({
  currentObjectId,
  templateId,
  templateName,
  blockedBy,
  blocking,
  dependencyToEdit = null,
  isBlockingDependency = false,
  initialStep = 1,
}: DependencyModalProps): JSX.Element {
  const { showModal, closeModal } = useCardModal();

  const blockingIds = blocking?.map((obj) => obj.modularObjectId) ?? [];
  const blockedByIds = blockedBy?.map((obj) => obj.blockedById) ?? [];

  const objectDependencies = blockingIds.concat(blockedByIds);

  const [selectedIds, setSelectedIds] = useState<string[]>([
    dependencyToEdit?.id,
    ...objectDependencies,
  ].filter(Boolean));

  // Add this new ref to store the original selectedIds
  const originalSelectedIdsRef = useRef<string[]>([...selectedIds]);

  useEffect(() => {
    metrics.track('dependency modal - open', { templateId, isBlockingDependency, template: templateName });
  }, [isBlockingDependency, templateId, templateName]);

  return (
    <MegaModal>
      <Stepper initialStep={dependencyToEdit ? 2 : initialStep}>
        <Step
          stepNumber={1}
          stepContent={DependencySelectionScreen}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          originalSelectedIdsRef={originalSelectedIdsRef}
          currentObjectId={currentObjectId}
          currentDependencies={objectDependencies}
          showModal={showModal}
          templateId={templateId}
          templateName={templateName}
          isBlockingDependency={isBlockingDependency}
          className='flex-1 min-h-0 gap-[24px]'
        />
        <Step
          stepNumber={2}
          stepContent={DependencyConfigScreen}
          selectedIds={selectedIds.filter(id => !originalSelectedIdsRef.current.includes(id))}
          setSelectedIds={setSelectedIds}
          originalSelectedIdsRef={originalSelectedIdsRef}
          isBlockingDependency={isBlockingDependency}
          currentObjectId={currentObjectId}
          dependencyToEdit={dependencyToEdit}
          closeModal={closeModal}
        />
      </Stepper>
    </MegaModal>
  );
}
