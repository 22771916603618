import type { Document } from '@/__generated__/types';
import FileLinkButton from '@/components/common/FilesLinks/FileLinkButton';
import { uploadNewFile } from '@/components/common/FilesLinks/utils';
import AddLinkModal from '@/components/modals/AddLinkModal/AddLinkModal';
import { MegaModalSearchInputV2 } from '@/components/modals/MegaModal/MegaModalSearchInput/MegaModalSearchInput';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useModal } from '@/hooks/useModal';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { postFiles } from '@/util/requests.functions';
import { type ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { v4, v5 } from 'uuid';
import { GetObjectCardObjectDocument } from '../Card/GetObjectCardObject.generated';
import { buildUpdateObjectDiffPayloadForDocument } from '../utils';
import { useAddFileToObjectMutation } from './AddFileToObject.generated';
import DocumentRow from './components/DocumentRow';
import DocumentRowHeader from './components/DocumentRowHeader';
import { markModularObjectUnread } from '@/apollo/reactiveVars/operations/mutations/unreadModularObjects';

export const FileUploadSection = () => {
  const user = useLoggedInUser();
  const { showModal } = useModal();

  const fileInputRef = useRef(null);
  const { objectCardData } = useObjectCardContext();

  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isLoadingLink, setIsLoadingLink] = useState(false);
  const [addFileToObject] = useAddFileToObjectMutation();
  const documents = objectCardData.documents;

  const openFileSelector = (): void => {
    fileInputRef.current.click();
  };

  const openLinkModal = (): void => {
    showModal(
      <AddLinkModal
        modularObjectId={objectCardData.id}
        onSubmitStart={() => setIsLoadingLink(true)}
        onSubmitEnd={() => setIsLoadingLink(false)}
      />,
      {
        showCloseIcon: false,
        className: '!w-[412px]',
      },
    );
  };

  const updateObjectWithFile = useCallback(async (file) => {
    await addFileToObject({
      variables: {
        input: {
          diffs: [
            buildUpdateObjectDiffPayloadForDocument({
              document: file,
              objectId: objectCardData.id,
              payloadType: 'addedFiles',
            }),
          ],
        },
      },
      refetchQueries: [GetObjectCardObjectDocument],
      onCompleted: async (data) => {
        const latestFiles = data.updateModularObject[0].files ?? [];

        const isSaved = latestFiles.some((f) => f.id === file.id);

        const SUCCESS_MESSAGE = isSaved
          ? 'Successfully saved file'
          : 'Successfully requested approval for adding file';

        markModularObjectUnread(objectCardData?.id);
        addToastSuccess(SUCCESS_MESSAGE);
      },
      onError: () => addToastError('Failed to add file, please try again'),
    });
  }, [addFileToObject, objectCardData.id]);

  const onFileSelect = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    setIsLoadingFile(true);

    for await (const file of Array.from(event.target.files)) {
      const response = await uploadNewFile(file);
      const responseFile = response?.file;
      const newFileId = v5(`${responseFile.name}-${v4()}`, user?.id);
      const newFile = {
        ...response.file,
        id: newFileId,
        externalId: objectCardData.id,
        externalType: 'modular_object',
      };
      await postFiles([newFile]);
      await updateObjectWithFile(newFile);
    }

    setIsLoadingFile(false);

    fileInputRef.current.value = null;
  };

  const [filterText, setFilterText] = useState('');

  const filteredDocuments = useMemo(() => {
    const documentList = documents ?? [];
    return documentList?.filter(
      document => {
        return (document.name || document.url).toLowerCase().includes(filterText.toLowerCase());
      },
    );
  }, [documents, filterText]);

  return (
    <>
      <div className='z-10 shadow-md px-[24px]'>
        <div className='flex gap-[16px]'>
          <FileLinkButton isLoading={isLoadingFile} onClick={openFileSelector} text='Add File' />
          <FileLinkButton isLoading={isLoadingLink} onClick={openLinkModal} text='Add Link' />
          <MegaModalSearchInputV2
            containerClassName='grow'
            placeholder='Filter...'
            value={filterText}
            onChange={(e) => {
              setFilterText(e.target.value);
            }}
          />
        </div>
        <DocumentRowHeader />
      </div>
      <div className='pt-[16px]'>
        {filteredDocuments?.map((document) => (
          <DocumentRow key={document.id} document={document as Document} isPendingApproval={!document.approved} />
        ))}
        {!filteredDocuments?.length && (
          <div className='flex justify-center items-center h-full'>
            <div className='font-medium opacity-60'>No files or links yet.</div>
          </div>
        )}
      </div>
      <input
        className='hidden'
        ref={fileInputRef}
        type='file'
        onChange={onFileSelect}
      />
    </>
  );
};
