import Alert from '@/components/Alert/Alert';
import { Button } from '@/designSystemComponents/Button';

import { faLightbulb } from '@fortawesome/sharp-regular-svg-icons';

interface NoOverlapReorderDependenciesAlertProps {
  objectName: string;
  setShowReorderDepsAlert: (show: boolean) => void;
  editOrSaveDependency: (correctedGapDays?: number) => Promise<void>;
  closeModal: () => void;
}

export default function NoOverlapReorderDependenciesAlert ({
  objectName,
  setShowReorderDepsAlert,
  closeModal,
  editOrSaveDependency,
}: NoOverlapReorderDependenciesAlertProps) {
  return (
    <>
      <div className='flex flex-col gap-5 max-w-[728px]'>
        <div className='flex justify-center mt-14'>
          <i className='fa-sharp fa-light fa-triangle-exclamation text-warning text-9xl' />
        </div>
        <h3 className='text-black effra-24 flex justify-center'>Dependencies Out of Order</h3>

        <p className='text-black mt-[-10px] effra-14 text-wrap flex justify-center text-center w-[60%] mx-auto'>
          You&apos;re trying to make a blocking relationship that looks out of order.<br />{' '}
          To fix this, we&apos;re going to move {objectName} which may cause any dependencies connected to {objectName}
          {' '}
          to change too.
        </p>

        <Alert icon={faLightbulb} className='mt-[10px] w-auto mx-auto text-[14px]'>
          If you proceed, the gap will be set to 0.
        </Alert>
      </div>

      <div className='flex gap-5 mt-[100px]'>
        <Button
          figmaProps={{ style: 'outline', size: 'small' }}
          className='flex-1'
          onClick={() => {
            setShowReorderDepsAlert(false);
          }}
        >
          Back
        </Button>

        <Button
          figmaProps={{ style: 'fill', size: 'small' }}
          className='flex-1'
          onClick={() => {
            editOrSaveDependency(0);
            closeModal();
          }}
        >
          Yes, Proceed
        </Button>
      </div>
    </>
  );
}
