import type { ModularObject } from '@/__generated__/types';
import CardModalProvider from '@/app/components/providers/CardModal';
import { EditContextProvider } from '@/components/cards';
import DriverListProvider from '@/components/common/Drivers/DriverSection/DriverList.context';
import { useDuplicateModularObjectsMutation } from '@/components/common/Drivers/DriverSection/DriverListActions/ConfirmationMessage/duplcateModularObject.generated';
import ListActions from '@/components/common/Drivers/DriverSection/DriverListActions/ListActions';
import ListActionProvider from '@/components/common/Drivers/DriverSection/ListActions.context';
import PrismaticProvider from '@/components/Contexts/prismatic.context';
import { Loader } from '@/components/loader';
import { ConfirmationModal } from '@/components/modals/ConfirmationModal';
import { GetTopLevelModularObjectsDocument } from '@/components/Schedule/queries/getTopLevelModularObjects.generated';
import { addSaveSuccessToast, addToastError } from '@/components/Toast/utils';
import DisabledObjectMessage from '@/components/tooltip/DisabledObjectMessage';
import { useDeveloperMode } from '@/hooks/useDeveloperMode';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useModal } from '@/hooks/useModal';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/sharp-solid-svg-icons';
import { uniq } from 'ramda';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useCopyToClipboard } from 'usehooks-ts';
import CollaborationColumn from '../../Collaboration/CollaborationColumn';
import Header from '../Card v2/Header/Header';
import { useDeleteModularObjectsMutation } from './deleteModularObjects.generated';
import { type GetObjectCardObjectQuery } from './GetObjectCardObject.generated';
import ObjectProvider from './Object.context';
import { getTheBandBackTogether } from './ObjectCard.util';
import ObjectCardContent from './ObjectCardContent';
import ObjectCardScrollingContainer from './ObjectCardScrollingContainer';
import { useQueryParams } from '@/hooks/useQueryParams';

export interface ObjectCardProps {
  forExternalLinks?: boolean;
  object: GetObjectCardObjectQuery['getModularObjectByIDs'][0];
}

function ObjectCard ({ forExternalLinks = false }: Readonly<ObjectCardProps>): JSX.Element {
  const apolloClient = useApolloClient();

  const loggedInUser = useLoggedInUser();
  const [duplicateObjects] = useDuplicateModularObjectsMutation();
  const { showModal, closeModal } = useModal();

  const {
    closeObjectCardDrawer,
    activeModularObjectId,
    isCollabModalOpen,
    objectCardData,
  } = useObjectCardContext();

  const { queryParams, updateUrlParams } = useQueryParams();

  const [deleteModularObjects] = useDeleteModularObjectsMutation();

  const collabModalStateRef = useRef(false);
  const [isCollaborationOpen, setIsCollaborationOpen] = useState(isCollabModalOpen);
  const toggleCollabModal = useCallback((): void => {
    setIsCollaborationOpen(o => {
      collabModalStateRef.current = !o;
      return !o;
    });
  }, [collabModalStateRef]);

  const activeObject = objectCardData;

  const [, copy] = useCopyToClipboard();
  const { isDeveloperModeEnabled } = useDeveloperMode();
  const handleCopy = (objectId: string) => () => {
    copy(objectId)
      .then(() => {
        // eslint-disable-next-line no-console
        console.log('Copied!', { activeModularObjectId: objectId });
      })
      .catch(error => {
        console.error('Failed to copy!', error);
      });
  };

  if (isDeveloperModeEnabled) {
    handleCopy(activeModularObjectId)();
  }

  const activeTemplate = activeObject?.template;

  const [userPermission, canUserEdit] = useMemo(() => {
    if (!loggedInUser) {
      return ['Viewer', false];
    }

    if (!activeObject?.id || activeObject?.ownerId === loggedInUser?.id) {
      return ['Owner', true];
    }

    const share = Object.values(activeObject.shares || {})?.find((share) => share?.userId === loggedInUser?.id);

    return [share?.role, share?.role === 'Editor'];
  }, [loggedInUser, activeObject?.id, activeObject?.ownerId, activeObject?.shares]);

  const getDefaultValues = useCallback(() => {
    const data = forExternalLinks
      ? activeObject?.data
      : getTheBandBackTogether(activeTemplate?.modules, activeObject?.data, activeObject?.customFields ?? {});

    return {
      ...activeObject,
      data,
      visibility: activeObject?.visibility || 'Private',
    };
  }, [activeObject, activeTemplate?.modules, forExternalLinks]);

  const methods = useForm({
    defaultValues: getDefaultValues(),
    values: getDefaultValues(),
  });

  const _onClose = useCallback(async (): Promise<void> => {
    // Reset the form
    methods.reset();

    closeObjectCardDrawer();
    await Promise.resolve();
  }, [closeObjectCardDrawer, methods]);

  const handleCloseCard = useCallback((collaborationIsOpen): void => {
    if (collaborationIsOpen.current) {
      toggleCollabModal();
      return;
    }

    void _onClose();
  }, [toggleCollabModal, _onClose]);

  const handleRemoveObject = useCallback(async (): Promise<void> => {
    // Show confirmation modal
    const handleConfirm = async () => {
      // Close confirmation modal
      closeModal();

      await deleteModularObjects({
        variables: {
          input: {
            ids: [objectCardData.id],
            shouldDeleteDrivers: true,
          },
        },
        update: async (cache, { data }) => {
          await _onClose();

          if (!data?.deleteModularObjects) return;

          data.deleteModularObjects.forEach((id: string) => {
            cache.evict({ id: cache.identify({ __typename: 'ModularObject', id }) });
            cache.gc();
          });

          cache.gc();
        },
        onError: (err) => {
          console.error(err);
          addToastError(`Failed to delete object.\n${err.message}`);
        },
      });
    };

    const confirmationMessage = (
      <>
        <div>Are you sure you want to delete this?</div>
        <div className='flex gap-1 items-center'>
          <FontAwesomeIcon className='text-pink' icon={faExclamationTriangle} />
          <span>All nested drivers will be deleted too.</span>
        </div>
      </>
    );

    showModal(
      <ConfirmationModal
        header='Delete Object'
        message={confirmationMessage}
        onConfirm={handleConfirm}
        onClose={closeModal}
        cancelText='Cancel'
        confirmText='Delete'
      />,
      {
        className: '!max-w-[400px]',
      },
    );
  }, [objectCardData, _onClose, showModal, closeModal, deleteModularObjects]);

  const handleDuplicateObject = useCallback(async (): Promise<void> => {
    try {
      const { data } = await duplicateObjects({
        variables: {
          ids: [objectCardData.id],
        },
      });

      if (!data?.duplicateModularObjects?.[0]) {
        throw new Error('Failed to duplicate object');
      }

      void apolloClient.refetchQueries({
        include: [GetTopLevelModularObjectsDocument],
      });

      addSaveSuccessToast(true, `Duplicated ${activeTemplate?.name ?? 'object'}.`);
    } catch (err) {
      console.error(err);
      addToastError(`Failed to duplicate ${activeTemplate?.name ?? 'object'}.\n${err.message ?? err}`);
    }
  }, [objectCardData, duplicateObjects, apolloClient, activeTemplate]);

  const pending = useMemo(
    () => objectCardData?.approvals?.filter(({ approvalType }) => approvalType === 'diff') ?? [],
    [objectCardData],
  );

  const requestedChanges = useMemo(() => {
    return uniq(pending.reduce((acc: string[], cur) => {
      if (cur.approvalType !== 'diff') {
        return acc;
      }

      return [
        ...acc,
        ...cur.requestedChanges.map((change) => `data.${change?.fieldId || ''}`),
      ];
    }, []));
  }, [pending]);

  useEffect(() => {
    if (queryParams?.analyticsDashboard) {
      const { analyticsDashboard, ...rest } = queryParams;
      updateUrlParams(rest);
      closeObjectCardDrawer();
    }
  }, [queryParams?.analyticsDashboard]);

  useEffect(() => {
    // Add event listener to handle closing the card when 'esc' is pressed
    const handleEsc = (e): void => {
      if (e.key === 'Escape') {
        handleCloseCard(collabModalStateRef);
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [handleCloseCard]);

  // Do not try to render card if we don't have data yet
  if (!objectCardData) {
    return (
      <div className='flex flex-1 justify-center items-center h-full'>
        <Loader className='max-h-[4rem] max-w-[4rem]' />
      </div>
    );
  }

  return (
    <div data-testid='object-card' className='flex overflow-hidden w-full h-full'>
      <CardModalProvider>
        <EditContextProvider
          canUserEdit={canUserEdit}
          isOwner={userPermission === 'Owner'}
          requestedChanges={[...new Set(requestedChanges)]}
        >
          <FormProvider {...methods}>
            <form
              data-testid='object-card-form'
              className='flex flex-col w-full'
            >
              <ObjectProvider
                activeModularObject={activeObject}
                activeTemplate={activeTemplate}
                isExternalLink={forExternalLinks}
              >
                <PrismaticProvider
                  objectId={activeModularObjectId}
                  templateType={activeTemplate?.type}
                  isOwner={userPermission === 'Owner'}
                >
                  <CollaborationColumn open={isCollaborationOpen} setIsOpen={toggleCollabModal} />
                  <Header
                    handleClose={() => {
                      handleCloseCard(collabModalStateRef);
                    }}
                    handleDuplicate={handleDuplicateObject}
                    handleDelete={handleRemoveObject}
                    openCollaborationModal={toggleCollabModal}
                    open={isCollaborationOpen}
                    canDelete={canUserEdit}
                  />
                  <DriverListProvider object={objectCardData as ModularObject}>
                    <ListActionProvider hardwareId={objectCardData?.id}>
                      <ObjectCardScrollingContainer>
                        <ObjectCardContent />
                      </ObjectCardScrollingContainer>
                      <ListActions />
                    </ListActionProvider>
                  </DriverListProvider>
                </PrismaticProvider>
              </ObjectProvider>
            </form>
          </FormProvider>
        </EditContextProvider>
        {objectCardData?.deactivated && (
          <div className='flex absolute top-0 right-0 bottom-0 left-0 z-50 justify-center items-center bg-white bg-opacity-40'>
            <DisabledObjectMessage
              objectId={activeModularObjectId}
              organizationId={objectCardData?.owner?.organizationId}
            />
          </div>
        )}
      </CardModalProvider>
    </div>
  );
}

export default memo(ObjectCard);
