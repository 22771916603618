import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDash, faLock } from '@fortawesome/sharp-solid-svg-icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export interface DependencyModularObjectProps {
  name: string;
  icon?: IconProp;
  startDate: Date;
  targetDate: Date;
  isLocked: boolean;
}

export default function DependencyModularObject (
  { name, icon, startDate, targetDate, isLocked }: DependencyModularObjectProps,
): JSX.Element {
  return (
    <div
      className='flex gap-[6px] border-[1px] border-[#CCCCCC] rounded-[2px] p-[4px] w-full'
      data-testid='dependency-modular-object'
    >
      <div className='flex justify-center w-[18px]'>
        <div className='flex items-center justify-center h-[24px]'>
          <FontAwesomeIcon icon={icon} className='text-[10px]' />
        </div>
      </div>
      <div className='flex flex-col flex-1 min-w-0'>
        <div className='flex items-center effra-14 font-bold h-[24px]'>
          <span className='truncate'>{name}</span>
        </div>
        <div className='flex items-center gap-[4px] effra-12 leading-[16px] h-[24px]'>
          <span className='truncate' data-testid='dependency-modular-object-start-date'>
            {dayjs(startDate).isValid() ? dayjs(startDate).format('MMM D, YYYY') : 'No start date'}
          </span>
          <FontAwesomeIcon icon={faDash} className='text-[#999999]' />
          <span className='truncate' data-testid='dependency-modular-object-target-date'>
            {dayjs(targetDate).isValid() ? dayjs(targetDate).utc().format('MMM D, YYYY') : 'No target date'}
          </span>
          {isLocked && (
            <FontAwesomeIcon
              icon={faLock}
              className='text-[#999999] ml-[4px] cursor-help'
              title='The start date or target date of this object is locked and cannot be changed'
            />
          )}
        </div>
      </div>
    </div>
  );
}
