import type * as Types from '../../../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetDependencyModularObjectsQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input'];
}>;


export type GetDependencyModularObjectsQuery = { __typename?: 'Query', getModularObjectByIDs: Array<{ __typename?: 'ModularObject', id: string, name: string | null, startDate: any | null, targetDate: any | null, startDateLocked: boolean | null, targetDateLocked: boolean | null, durationLocked: boolean | null, template: { __typename?: 'Template', type: Types.TemplateTypes | null, subType: Types.TemplateSubTypes | null } | null }> | null };


export const GetDependencyModularObjectsDocument = gql`
    query GetDependencyModularObjects($ids: [UUID!]!) {
  getModularObjectByIDs(ids: $ids) {
    id
    name
    startDate
    targetDate
    startDateLocked
    targetDateLocked
    durationLocked
    template {
      type
      subType
    }
  }
}
    `;

/**
 * __useGetDependencyModularObjectsQuery__
 *
 * To run a query within a React component, call `useGetDependencyModularObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDependencyModularObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDependencyModularObjectsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetDependencyModularObjectsQuery(baseOptions: Apollo.QueryHookOptions<GetDependencyModularObjectsQuery, GetDependencyModularObjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDependencyModularObjectsQuery, GetDependencyModularObjectsQueryVariables>(GetDependencyModularObjectsDocument, options);
      }
export function useGetDependencyModularObjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDependencyModularObjectsQuery, GetDependencyModularObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDependencyModularObjectsQuery, GetDependencyModularObjectsQueryVariables>(GetDependencyModularObjectsDocument, options);
        }
export function useGetDependencyModularObjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDependencyModularObjectsQuery, GetDependencyModularObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDependencyModularObjectsQuery, GetDependencyModularObjectsQueryVariables>(GetDependencyModularObjectsDocument, options);
        }
export type GetDependencyModularObjectsQueryHookResult = ReturnType<typeof useGetDependencyModularObjectsQuery>;
export type GetDependencyModularObjectsLazyQueryHookResult = ReturnType<typeof useGetDependencyModularObjectsLazyQuery>;
export type GetDependencyModularObjectsSuspenseQueryHookResult = ReturnType<typeof useGetDependencyModularObjectsSuspenseQuery>;
export type GetDependencyModularObjectsQueryResult = Apollo.QueryResult<GetDependencyModularObjectsQuery, GetDependencyModularObjectsQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDependencyModularObjectsQuery((req, res, ctx) => {
 *   const { ids } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectByIDs })
 *   )
 * })
 */
export const mockGetDependencyModularObjectsQuery = (resolver: Parameters<typeof graphql.query<GetDependencyModularObjectsQuery, GetDependencyModularObjectsQueryVariables>>[1]) =>
  graphql.query<GetDependencyModularObjectsQuery, GetDependencyModularObjectsQueryVariables>(
    'GetDependencyModularObjects',
    resolver
  )
