import type { DependencyBlockType } from '@/__generated__/types';
import { useObjectCardScrollContext } from '@/components/common/ModularObject/Card/ObjectCardScroll.context';
import type { StepContentProps } from '@/components/Stepper/Stepper';
import metrics from '@/util/metrics';
import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/sharp-regular-svg-icons';
import { faShield } from '@fortawesome/sharp-solid-svg-icons';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import FixedReorderDependenciesAlert from '../../FixedReorderDependenciesAlert/FixedReorderDependenciesAlert';
import NoOverlapReorderDependenciesAlert from '../../NoOverlapReorderDependenciesAlert/NoOverlapReorderDependenciesAlert';
import getPostDependenciesInput from '../../utils/getPostDependenciesInput';
import { addDependencies, editDependencies } from '../depencies.service';
import { handleDependencyErrors } from '../errors.utils';
import { useGetGapDataQuery } from '../getGapData.generated';
import DependencyConfigButtons from './DependencyConfigButtons/DependencyConfigButtons';
import DependencyGapInput from './DependencyGapInput/DependencyGapInput';
import DependencyModularObject from './DependencyModularObject/DependencyModularObject';
import DependencyTypeSelect from './DependencyTypeSelect/DependencyTypeSelect';
import { useGetDependencyModularObjectsQuery } from './queries/getDependencyModularObjects.generated';

export interface EditableDependency {
  id: string;
  blockedById: string;
  modularObjectId: string;
  calculatedGapDays: number;
  blockTypeToEdit: DependencyBlockType;
}

export interface DependencyConfigScreenProps extends StepContentProps {
  closeModal: () => void;
  currentObjectId: string;
  dependencyToEdit?: Readonly<EditableDependency>;
  originalSelectedIdsRef: React.MutableRefObject<string[]>;
  isBlockingDependency: boolean;
  selectedIds: string[];
}

export default function DependencyConfigScreen (
  {
    closeModal,
    currentObjectId,
    dependencyToEdit,
    decrementStep,
    originalSelectedIdsRef,
    isBlockingDependency,
    selectedIds,
  }: DependencyConfigScreenProps,
) {
  const apolloClient = useApolloClient();
  const { scrollToId } = useObjectCardScrollContext();

  const [gapDays, setGapDays] = useState<number>(0);
  const [
    selectedGapType,
    setSelectedGapType,
  ] = useState<DependencyBlockType>(dependencyToEdit?.blockTypeToEdit ?? 'none');
  const [currentObjectStartDate, setCurrentObjectStartDate] = useState<Date | null>(null);
  const [currentObjectTargetDate, setCurrentObjectTargetDate] = useState<Date | null>(null);
  const [shouldShowReorderDepsAlert, setShouldShowReorderDepsAlert] = useState(false);

  const isAddingMultipleDependencies = Math.abs(selectedIds?.length - originalSelectedIdsRef?.current?.length) > 1;

  const ids: string[] = useMemo(() => {
    if (dependencyToEdit) {
      return [dependencyToEdit.blockedById, dependencyToEdit.modularObjectId];
    }

    return [...selectedIds, currentObjectId];
  }, [dependencyToEdit, selectedIds, currentObjectId]);

  // Get the information about the modular objects in the dependency
  const { data: modularObjectData, error: modularObjectError } = useGetDependencyModularObjectsQuery({
    variables: {
      ids,
    },
    skip: ids.length === 0,
  });

  if (modularObjectError) {
    console.error('There was an error fetching the modular objects', modularObjectError);
  }

  // Determine which modular object is the "blocked by" and which is the "modular object"
  const { dependentModularObject, dependentBlockedBy, currentModularObject } = useMemo(() => {
    const modularObjects = {
      dependentModularObject: null,
      dependentBlockedBy: null,
      currentModularObject: null,
    };

    modularObjectData?.getModularObjectByIDs?.forEach(modularObject => {
      // Creating a new dependency
      if (!dependencyToEdit) {
        if (isBlockingDependency) {
          if (modularObject.id === currentObjectId) {
            modularObjects.dependentBlockedBy = modularObject;
          } else {
            modularObjects.dependentModularObject = modularObject;
          }
        } else {
          if (modularObject.id === currentObjectId) {
            modularObjects.dependentModularObject = modularObject;
          } else {
            modularObjects.dependentBlockedBy = modularObject;
          }
        }

        return;
      }

      // Editing an existing dependency
      if (modularObject.id === dependencyToEdit?.blockedById) {
        modularObjects.dependentBlockedBy = modularObject;
      } else {
        modularObjects.dependentModularObject = modularObject;
      }
    });

    if (modularObjects.dependentModularObject && modularObjects.dependentBlockedBy) {
      modularObjects.currentModularObject = [modularObjects.dependentModularObject, modularObjects.dependentBlockedBy]
        .find(modularObject => modularObject.id === currentObjectId);
    }

    return modularObjects;
  }, [modularObjectData, dependencyToEdit, currentObjectId, isBlockingDependency]);

  // This monstrosity is here to initialize the currentObjectStartDate and currentObjectTargetDate if they're not already set
  // TODO: Do it better
  useEffect(() => {
    if (!currentObjectStartDate && currentModularObject?.startDate) {
      setCurrentObjectStartDate(currentModularObject.startDate);
    }

    if (!currentObjectTargetDate && currentModularObject?.targetDate) {
      setCurrentObjectTargetDate(currentModularObject.targetDate);
    }
  }, [currentModularObject, currentObjectStartDate, currentObjectTargetDate]);

  const [dependentModularObjectStartDate, dependentModularObjectTargetDate] = useMemo(() => {
    if (currentModularObject?.id === dependentModularObject?.id) {
      return [currentObjectStartDate, currentObjectTargetDate];
    }

    return [dependentModularObject?.startDate, dependentModularObject?.targetDate];
  }, [currentModularObject, dependentModularObject, currentObjectStartDate, currentObjectTargetDate]);

  const [dependentBlockedByStartDate, dependentBlockedByTargetDate] = useMemo(() => {
    if (currentModularObject?.id === dependentBlockedBy?.id) {
      return [currentObjectStartDate, currentObjectTargetDate];
    }

    return [dependentBlockedBy?.startDate, dependentBlockedBy?.targetDate];
  }, [currentModularObject, dependentBlockedBy, currentObjectStartDate, currentObjectTargetDate]);

  // If we have an updated gapDays value, we need to figure out which modular object is the current object, and change that object's dates to account for the updated gapDays
  const dependencyInputs = isBlockingDependency
    ? selectedIds.map(id => ({
      modularObjectId: id,
      blockedById: currentObjectId,
    }))
    : selectedIds.map(id => ({
      modularObjectId: currentObjectId,
      blockedById: id,
    }));

  const { data: gapDataResponse, loading: isLoadingGapData, error: gapDataError } = useGetGapDataQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        throughModularObjectId: currentObjectId,
        dependencyInputs,
      },
    },
    // skip: Boolean(dependencyToEdit), // Adding this created a bug that would cause the msw mock to not be called in the tests, so commenting it out for now so we don't accidentally add it back in without context
  });

  if (gapDataError) {
    console.error('There was an error fetching the gap data', gapDataError);
  }

  // Gets the gap days from the dependencyToEdit if it exists, otherwise gets the calculated gap days from the gapDataResponse
  useEffect(() => {
    if (dependencyToEdit) {
      setGapDays(dependencyToEdit.calculatedGapDays);
      return;
    }

    if (!gapDataResponse) {
      setGapDays(0);
      return;
    }

    const gapTypeWithDefault = selectedGapType ?? 'none';
    const _gapDays = gapDataResponse?.getDependencyGapCalcs[0][gapTypeWithDefault]?.calculatedGapDays ?? 0; // We only display a calculated gap day if were working with one dependency, so we can just use the first item in the array

    setGapDays(_gapDays);
  }, [gapDataResponse, selectedGapType, dependencyToEdit]);

  const handleGapChange = (value: number) => {
    // First we have to figure out which ends of the dependent modular objects are the one's we're going to be calculating the new dates from
    const startDate = dependentBlockedBy?.targetDate;
    const targetDate = dependentModularObject?.startDate;

    if (!startDate || !targetDate) return;

    // Now we need to determine which object we're going to change the dates for and which is the static object
    const [updatingModularObject, staticModularObject] = currentObjectId === dependentModularObject.id
      ? [dependentModularObject, dependentBlockedBy]
      : [dependentBlockedBy, dependentModularObject];

    // Now that we have the correct objects and know which ones we're updating, we need to check the object we're updating's 'startDateLocked' and 'targetDateLocked' values
    // If the object is locked, we want to make sure we don't update the dates and we don't update the gapDays
    const isStartDateLocked = updatingModularObject.startDateLocked;
    const isTargetDateLocked = updatingModularObject.targetDateLocked;

    if (isStartDateLocked || isTargetDateLocked) {
      return;
    }

    // Get the duration of the updating modular object
    const updatingModularObjectDuration = dayjs(updatingModularObject.targetDate).diff(
      updatingModularObject.startDate,
      'day',
    );

    let newStartDate: Date;
    let newTargetDate: Date;

    if (isBlockingDependency) {
      newTargetDate = dayjs(staticModularObject.startDate).subtract(value, 'day').toDate();
      newStartDate = dayjs(newTargetDate).subtract(updatingModularObjectDuration, 'day').toDate();
    } else {
      newStartDate = dayjs(staticModularObject.targetDate).add(value, 'day').toDate();
      newTargetDate = dayjs(newStartDate).add(updatingModularObjectDuration, 'day').toDate();
    }

    // We now have the updated dates for the updating modular object, now we need to update the UI to reflect the new dates
    setCurrentObjectStartDate(newStartDate);
    setCurrentObjectTargetDate(newTargetDate);
    setGapDays(value);
  };

  const handleAddDependencies = async (correctedGapDays?: number): Promise<void> => {
    const postDependenciesInput = getPostDependenciesInput(
      gapDataResponse,
      selectedGapType,
      correctedGapDays ?? Number(gapDays),
      isAddingMultipleDependencies,
    );

    metrics.track('dependency modal - add dependencies', {
      amountSelected: postDependenciesInput?.length,
      blockType: selectedGapType,
      gap: {
        days: gapDays,
      },
    });

    try {
      await addDependencies({
        dependencies: postDependenciesInput,
        objectId: currentObjectId,
        apolloClient,
      });

      scrollToId('dependencies-section');
      closeModal();
    } catch (e) {
      handleDependencyErrors(e);
    }
  };

  const handleEditDependencies = async (correctedGapDays?: number): Promise<void> => {
    metrics.track('dependency modal - edit dependencies', {
      blockType: selectedGapType,
      gap: {
        days: gapDays,
      },
    });

    try {
      await editDependencies({
        dependencyToEdit,
        gapDays: correctedGapDays?.toString() ?? gapDays.toString(),
        selectedGapType,
        objectId: currentObjectId,
        apolloClient,
      });

      scrollToId('dependencies-section');
      closeModal();
    } catch (e) {
      handleDependencyErrors(e);
    }
  };

  const editOrSaveDependency = dependencyToEdit
    ? handleEditDependencies
    : handleAddDependencies;

  const handleDependencySave = async () => {
    if (Number(gapDays) < 0 && selectedGapType !== 'none') {
      setShouldShowReorderDepsAlert(true);
      return;
    }

    closeModal();
    await editOrSaveDependency();
  };

  if (shouldShowReorderDepsAlert) {
    return selectedGapType === 'noOverlap' ?
      (
        <NoOverlapReorderDependenciesAlert
          objectName={currentModularObject?.name}
          setShowReorderDepsAlert={setShouldShowReorderDepsAlert}
          editOrSaveDependency={editOrSaveDependency}
          closeModal={closeModal}
        />
      ) :
      (
        <FixedReorderDependenciesAlert
          objectName={currentModularObject?.name}
          editOrSaveDependency={editOrSaveDependency}
          closeModal={closeModal}
        />
      );
  }

  return (
    <div
      data-testid='dependency-config-screen-v2'
      className='flex flex-col gap-[16px] w-full max-w-[630px] bg-white'
    >
      <h2 className='effra-24 text-black font-bold leading-[22px]'>Confirm Your Dependencies</h2>
      <div className='flex gap-[12px]'>
        {/* Only display the modular objects and gap options if we're editing a dependency or if there's only one item selected from the previous step */}
        {(dependencyToEdit || selectedIds.length === 1) && (
          <div
            className='flex flex-col gap-[8px] flex-1 justify-between items-center'
            data-testid='dependency-config-modular-objects-container'
          >
            <DependencyModularObject
              name={dependentBlockedBy?.name ?? 'Unknown'}
              icon={faShield}
              startDate={dependentBlockedByStartDate ?? new Date()} // TODO: Handle unknown date better
              targetDate={dependentBlockedByTargetDate ?? new Date()} // TODO: Handle unknown date better
              isLocked={dependentBlockedBy?.startDateLocked || dependentBlockedBy?.targetDateLocked}
            />
            <DependencyGapInput
              disabled={currentModularObject?.startDateLocked || currentModularObject?.targetDateLocked ||
                selectedGapType === 'none'}
              disabledTooltipText={selectedGapType === 'none'
                ? 'The gap cannot be changed because the dependency type is set to none'
                : 'The gap cannot be changed because the start date or target date of one of the objects is locked'}
              isLoading={isLoadingGapData}
              onChange={handleGapChange}
              value={gapDays}
            />
            <DependencyModularObject
              name={dependentModularObject?.name ?? 'Unknown'}
              icon={faShield}
              startDate={dependentModularObjectStartDate ?? new Date()} // TODO: Handle unknown date better
              targetDate={dependentModularObjectTargetDate ?? new Date()} // TODO: Handle unknown date better
              isLocked={dependentModularObject?.startDateLocked || dependentModularObject?.targetDateLocked}
            />
          </div>
        )}
        <div className='flex-1'>
          <DependencyTypeSelect
            checkedValue={selectedGapType}
            onChange={setSelectedGapType}
          />
        </div>
      </div>
      <div className='flex gap-[8px] items-center'>
        <FontAwesomeIcon icon={faWarning} className='text-[#EF2F95]' />
        <span className='effra-12 text-[#666666]'>
          Date changes made as a result of moving dependencies are not subject to approvals.
        </span>
      </div>
      <DependencyConfigButtons
        isAddDisabled={!Number.isInteger(gapDays)}
        isEditing={Boolean(dependencyToEdit)}
        onBackClick={decrementStep}
        onAddClick={handleDependencySave}
      />
    </div>
  );
}
