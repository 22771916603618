import getIcon from '@/util/getIcon';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import SidenavSection from '../components/SidenavSection';
import SidenavTab from '../components/SidenavTab';
import { useGetProgramsQuery } from './queries/getPrograms.generated';

const PAGE_SIZE = 10;

export default function ProgramsTabs (): JSX.Element {
  const router = useRouter();

  const [page, setPage] = useState(0);

  const { data, fetchMore } = useGetProgramsQuery({
    variables: {
      paginationInput: {
        offset: page * PAGE_SIZE,
        limit: PAGE_SIZE,
        direction: 'FORWARD',
        time: null,
      },
    },
  });

  const [programs, hasNextPage] = useMemo(
    () => [
      (data?.getPrograms?.edges || [])?.sort((a, b) => a.name.localeCompare(b.name)),
      data?.getPrograms?.pageInfo?.hasNextPage,
    ],
    [data],
  );

  const handleLoadMoreClick = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.stopPropagation();
    event.preventDefault();

    const newPage = page + 1;

    await fetchMore({
      variables: {
        paginationInput: {
          offset: newPage * PAGE_SIZE,
          limit: PAGE_SIZE,
          direction: 'FORWARD',
          time: null,
        },
      },
    });

    setPage(newPage);
  };

  return (
    <SidenavSection title='Programs'>
      {programs.map((program) => (
        <SidenavTab
          key={`nav-modular-object-${program.id}`}
          name={program.name}
          icon={getIcon(
            program?.template?.name?.toLowerCase(),
            '@expanded/sidenav:text-[12px] @expanded/sidenav:height-[12px] text-[16px] height-[16px]',
          )}
          href={`/schedule?filters=parent:${program.id}`}
          isActive={router.pathname === '/schedule' && router?.query?.filters === `parent:${program.id}`}
        />
      ))}
      {hasNextPage && (
        <SidenavTab
          name='Load More'
          className='opacity-80 transition-opacity hover:opacity-100 bg-white/20'
          onClick={handleLoadMoreClick}
        />
      )}
    </SidenavSection>
  );
}
