import { useState } from 'react';
import Portal from '@/components/Portal/Portal';
import { useGetOverlapConversionDataQuery, type GetOverlapConversionDataQuery } from './getOverlapConversionData.generated';
import ModalBG from '@/components/Portal/ModalBG';
import { Button } from '@/designSystemComponents/Button';
import CustomCheckbox from '@/components/modules/CustomCheckbox';
import Alert from '@/components/Alert/Alert';
import { faLightbulb } from '@fortawesome/pro-solid-svg-icons';
import { useDismissOverlapConversionAlertMutation } from './dismissOverlapConversionAlert.generated';
import { useLocalStorage } from '@/hooks/useLocalStorage';

function ObjectRow({ object }: { object: GetOverlapConversionDataQuery['getDependencyOverlapConversionObjects'][number] }) {
    return (
        <div className='flex flex-row items-center'>
            <div className='flex-1 min-w-0 truncate'>{object.objectName}</div>
            <div className='flex-shrink-0 ml-4 w-[90px]'>{new Date(object.objectStartDate).toLocaleDateString()}</div>
            <div className='flex-shrink-0 ml-4 w-[90px]'>{new Date(object.objectTargetDate).toLocaleDateString()}</div>
        </div>
    );
}

const handleDownloadCSV = (overlapConversionData: GetOverlapConversionDataQuery['getDependencyOverlapConversionObjects']) => {
    const csvContent = 'data:text/csv;charset=utf-8,Object Name,Start Date,Target Date,Previous Dependency Type,New Dependency Type\n'
        + overlapConversionData.map(object =>
            `${object.objectName},${new Date(object.objectStartDate).toLocaleDateString()},${new Date(object.objectTargetDate).toLocaleDateString()},No Overlap,None`
        ).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'affected_objects.csv');
    document.body.appendChild(link);
    link.click();
};

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

export default function NoOverlapChangeAlert() {
    const { data: overlapConversionData, loading: isLoading } = useGetOverlapConversionDataQuery();
    const [dismissOverlapConversionAlert] = useDismissOverlapConversionAlertMutation();

    const overlappingDependencies = overlapConversionData?.getDependencyOverlapConversionObjects ?? [];
    const uniqueOverlappingDependencies = overlappingDependencies.filter((object, index, self) =>
        index === self.findIndex(t => t.objectName === object.objectName)
    ).sort((a, b) => new Date(a.objectStartDate).getTime() - new Date(b.objectStartDate).getTime());

    const isDismissedForever = overlappingDependencies?.some(object => object.dismissedByUser);
    const [stopShowingModal, setStopShowingModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(!isDismissedForever);
    const [{ lastShownDate }, setShownOverlapAlert] = useLocalStorage('shownOverlapAlert', {
        lastShownDate: null
    });

    const isWithin24Hours = Date.now() - lastShownDate < ONE_DAY_IN_MS;
    const doNotShowModal = (
        isLoading ||
        isDismissedForever ||
        isWithin24Hours ||
        overlappingDependencies.length === 0
    );

    if (doNotShowModal) return null;

    return (
        <Portal>
            <ModalBG open={isModalOpen} disableBackgroundDismissal={true}>
                <div className='w-[400px] flex relative flex-col gap-4 bg-white border-warning p-6 rounded-lg shadow-lg'>

                    <div className='flex justify-center'>
                        <h2 className='effra-24 font-bold text-warning'>
                            We changed dependencies!
                        </h2>
                    </div>
                    <Alert iconPosition='top' icon={faLightbulb} className='my-[10px] w-auto mx-auto text-[14px] gap-4 !items-start'>
                        Some of your objects were changed to &quot;none&quot; because the dependency relationships were no longer valid.
                    </Alert>
                    <div className='flex flex-col gap-1'>
                        <p className='mb-4'>The following objects were affected:</p>
                        <div className='border border-gray-300 rounded-md px-4 pb-4 max-h-48 overflow-y-auto'>
                            <div className='flex flex-row font-bold py-2 mb-2 sticky top-0 bg-white'>
                                <div className='flex-1 min-w-0'>Object Name</div>
                                <div className='flex-shrink-0 ml-4 w-[90px]'>Start Date</div>
                                <div className='flex-shrink-0 ml-4 w-[90px]'>Target Date</div>
                            </div>
                            {uniqueOverlappingDependencies.map(object => (
                                <ObjectRow key={object.id} object={object} />
                            ))}
                        </div>
                    </div>
                    <div className="flex items-center mt-4">
                        <CustomCheckbox
                            selected={stopShowingModal}
                            onCheck={() => setStopShowingModal(!stopShowingModal)}
                        />
                        <label htmlFor="stopShowingModal" className="ml-2 text-sm font-medium text-gray-900">Don&apos;t show this message again</label>
                    </div>
                    <Button
                        figmaProps={{ size: 'large', style: 'fill' }}
                        onClick={() => {
                            handleDownloadCSV(uniqueOverlappingDependencies);
                            setIsModalOpen(false);
                            setShownOverlapAlert({
                                lastShownDate: Date.now() + ONE_DAY_IN_MS,
                            });
                            if (stopShowingModal) {
                                dismissOverlapConversionAlert();
                            }
                        }}
                    >
                        Download CSV
                    </Button>
                </div>
            </ModalBG >
        </Portal >
    );
}
