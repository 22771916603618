import { ConfirmationModal } from '@/components/modals';
import { addToastError } from '@/components/Toast/utils';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useModal } from '@/hooks/useModal';
import { type FilterQueryParams, useQueryParams } from '@/hooks/useQueryParams';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/sharp-regular-svg-icons';
import { faGridRound2 } from '@fortawesome/sharp-solid-svg-icons';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import SidenavSection from '../../components/SidenavSection';
import SidenavTab from '../../components/SidenavTab';
import { useDeleteFilterMutation } from './deleteFilter.generated';

export default function FiltersTabs (): JSX.Element {
  const router = useRouter();
  const loggedInUser = useLoggedInUser();
  const { showModal, closeModal } = useModal();
  const [deleteFilter] = useDeleteFilterMutation();
  const { queryParams, updateUrlParams } = useQueryParams<FilterQueryParams>();

  const filters = loggedInUser?.filters || [];

  const getQueryString = useCallback((filterId: string) => {
    let isFilterIncluded = false;
    const query = Object.entries(router.query).map(([key, value]) => {
      // Remove the 'filters' query param from the query string
      if (key === 'filters') {
        return '';
      }

      // Override filter query param if it is already set in the query params
      if (key === 'filter') {
        isFilterIncluded = true;
        return `filter=${filterId}`;
      }

      const valueString = Array.isArray(value) ? value.join(',') : value;

      return `${key}=${valueString}`;
    });

    if (!isFilterIncluded) {
      query.push(`filter=${filterId}`);
    }

    return query.join('&');
  }, [router.query]);

  return (
    <SidenavSection title='Saved Filters'>
      {filters.map((filter) => (
        <SidenavTab
          key={`nav-modular-object-${filter?.id}`}
          name={filter?.name}
          icon={<FontAwesomeIcon icon={faGridRound2} />}
          href={`/schedule?${getQueryString(filter?.id)}`}
          isActive={router.pathname === '/schedule' && router?.query?.filter === filter.id}
          actionIcon={<FontAwesomeIcon icon={faTrash} />}
          actionTitle='Delete Filter'
          actionOnClick={() => {
            showModal(
              <ConfirmationModal
                confirmText='Delete'
                header='Are you sure?'
                onConfirm={async () => {
                  const { errors } = await deleteFilter({
                    variables: { id: filter?.id },
                    refetchQueries: ['GetLoggedInUser'],
                  });

                  if (errors?.length) {
                    errors.forEach((error) => {
                      console.error('error deleting filter', error);
                      addToastError(error?.message || 'Failed to delete filter');
                    });
                    return;
                  }

                  if (queryParams.filter === filter?.id) {
                    // Remove the filter query param from the url now that it's deleted
                    const { filter: _, ...rest } = queryParams;
                    updateUrlParams({ ...rest });
                  }

                  closeModal();
                }}
                onClose={closeModal}
                message={`This will permanently delete the filter "${filter?.name}"`}
                cancelText='Cancel'
              />,
            );
          }}
        />
      ))}
    </SidenavSection>
  );
}
