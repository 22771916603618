import type { TypedTypePolicies } from '@/__generated__/apollo-type-policies';
import { InMemoryCache } from '@apollo/client';
import { localVariablesTypePolicies } from './localVariables';

const typePolicies: TypedTypePolicies = {
  ...localVariablesTypePolicies,
  Query: {
    fields: {
      getRecentActivityByUserId: {
        keyArgs: ['input', ['userId', 'type']],
        merge: mergeNewPage,
      },
      teamMembers: {
        keyArgs: ['input', ['query', ['teamId']]],
        merge: mergeNewPage,
      },
      getDependencyCandidatesForObjectId: {
        keyArgs: ['input', ['id', 'filter']],
        merge: mergeNewPage,
      },
      getPrograms: {
        keyArgs: ['input', ['paginationInput']],
        merge: mergeNewPage,
      },
      getTopLevelModularObjects: {
        keyArgs: ['filters', 'childFilters'],
        merge: mergeNewPage,
      },
    },
  },
};

export const getNewApolloCache = () => new InMemoryCache({ typePolicies });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mergeNewPage (existing: any, incoming: any) {

  // If there are existing edges and incoming edges, handle merging them
  if (existing?.edges) {
    const existingIds = existing.edges.map((edge) => edge.__ref);
    const incomingIds = incoming.edges.map((edge) => edge.__ref);

    let newEdges = [];

    // Check if any incoming Ids are already in the existing edges
    const shouldUpdateExistingEdges = incomingIds.some((id) => existingIds.includes(id));

    let updatedExistingEdges = [...existing.edges];
    // If any incoming edges are already in the existing edges,
    // replace the existing edges with the incoming edges

    if (shouldUpdateExistingEdges) {
      updatedExistingEdges = existing.edges.map((edge) => {
        const incomingEdge = incoming.edges.find((incomingEdge) => incomingEdge.__ref === edge.__ref);

        // If found a matching incoming edge, replace the existing edge
        return incomingEdge ?? edge;
      });

      // Filter out any incoming edges that are already in the existing edges
      const newIncomingEdges = incoming.edges.filter((incomingEdge) => !existingIds.includes(incomingEdge.__ref));

      // Append any new edges to the updated existing edges
      newEdges = [...updatedExistingEdges, ...newIncomingEdges];

      return {
        pageInfo: { ...incoming.pageInfo },
        edges: newEdges,
      };
    }

    // If there is no overlap, return the incoming edges appended to the existing edges
    return {
      pageInfo: { ...incoming.pageInfo },
      edges: [...existing.edges, ...incoming.edges],
    };

  } else {
    // If there are no existing edges, just return the incoming
    return incoming;
  }
}
