import type { DependencyBlockType } from '@/__generated__/types';
import DependencyTypeSelectOption from './DependencyTypeSelectOption/DependencyTypeSelectOption';

export interface DependencyTypeSelectProps {
  checkedValue: DependencyBlockType;
  onChange: (type: DependencyBlockType) => void;
}

export default function DependencyTypeSelect ({ checkedValue, onChange }: DependencyTypeSelectProps): JSX.Element {
  return (
    <div className='flex flex-col gap-[8px] bg-[#FAFAFA] rounded-[8px] p-[16px]'>
      <h4 className='effra-14 font-bold'>Set Dependency Type</h4>
      <p>Select how items’ dates change when influenced by a dependency.</p>
      <div className='flex flex-col gap-[8px] py-[8px]'>
        <DependencyTypeSelectOption type='none' checkedValue={checkedValue} onChecked={onChange} />
        <DependencyTypeSelectOption type='strict' checkedValue={checkedValue} onChecked={onChange} />
        <DependencyTypeSelectOption type='noOverlap' checkedValue={checkedValue} onChecked={onChange} />
      </div>
    </div>
  );
}
