import type { DiffInput } from '@/__generated__/types';
import { markModularObjectUnread } from '@/apollo/reactiveVars/operations/mutations/unreadModularObjects';
import { GetHistoryUpdatesForObjectIdDocument } from '@/components/cards/UpdatesColumn/getHistoryUpdatesForObjectId.generated';
import { GetModularObjectHistoryByIdDocument } from '@/components/cards/UpdatesColumn/getModularObjectDiffs.generated';
import SelectIcon from '@/components/modules/SelectBoxInput/SelectIcon';
import { useUpdateAttributeMutation } from '@/components/modules/UpdateAttribute.generated';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { type Module } from '@/models/template.model';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { buildFieldsPendingApprovalMap } from '@/util/approvals';
import { Listbox, Transition } from '@headlessui/react';
import cx from 'classnames';
import { Fragment, useCallback, useState } from 'react';
import { v5 as uuidv5 } from 'uuid';

function toCamelCase (str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase())
    .replace(/\s+/g, '');
}

const NAMESPACE = '740236e4-0a2d-456a-ac2f-5ace764e3aee';

export const getObjectPayload = ({ type, label, prefix = '', suffix = '' }) => {
  return {
    name: label,
    type,
    order: 1,
    value: '',
    properties: {
      className: 'grow',
      prefix,
      suffix,
      inputProps: {
        className: 'input-text',
        name: toCamelCase(label),
      },
      labelProps: {
        name: toCamelCase(label),
        label,
      },
    },
  };
};

export function generateCustomFieldObject ({ type, label, prefix = '', suffix = '' }) {
  const objectKey = uuidv5(label, NAMESPACE);
  const newCustomFieldObject = {
    [objectKey]: getObjectPayload({ type, label, prefix, suffix }),
  };

  return newCustomFieldObject;
}

const CUSTOM_FIELD_OPTIONS = [
  { label: 'Text Input', value: 'textInput' },
  { label: 'Number Input', value: 'numberInput' },
];

interface AddCustomFieldModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: (value: any) => void;
  closeModal: () => void;
  existingModules: Record<string, Module>;
}
export default function AddCustomFieldModal ({ setValue, closeModal, existingModules }: AddCustomFieldModalProps) {
  const [selectedItem, setSelectedItem] = useState({ label: 'Select Field Type', value: '' });
  const [updateAttribute] = useUpdateAttributeMutation();
  const { objectCardData } = useObjectCardContext();

  const buildUpdateObjectDiffPayloadForNewCustomAttribute = useCallback(({ objectId, fieldName, newFieldValue }: {
    objectId: string;
    fieldName: string;
    newFieldValue: ReturnType<typeof getObjectPayload>;
  }) => {
    const payload: DiffInput = {
      externalID: objectId,
      externalType: 'modular_object',
      diff: {
        to: { customFields: { [fieldName]: newFieldValue, ...existingModules } },
        displayNames: { customFields: 'customFields' },
      },
      fromMigration: false,
      diffType: 'updated',
    };

    return payload;
  }, [existingModules]);

  const addCustomField = useCallback(
    async (
      { newFieldValue, attributeName }: {
        newFieldValue: ReturnType<typeof getObjectPayload>;
        attributeName: string;
      },
    ) => {
      const fieldName = attributeName.split('.').slice(-2).shift();
      const updateNamePayload = buildUpdateObjectDiffPayloadForNewCustomAttribute({
        objectId: objectCardData?.id,
        fieldName,
        newFieldValue,
      });

      await updateAttribute({
        variables: {
          input: {
            diffs: [updateNamePayload],
          },
        },
        refetchQueries: [GetModularObjectHistoryByIdDocument, GetHistoryUpdatesForObjectIdDocument],
        onCompleted: (data) => {
          const fieldsPendingApprovalMap = buildFieldsPendingApprovalMap(data.updateModularObject?.[0]?.approvals);
          const isSaved = !fieldsPendingApprovalMap[fieldName];

          const SUCCESS_MESSAGE = isSaved
            ? 'Successfully saved new custom field'
            : 'Successfully requested approval for custom field addition';

          addToastSuccess(SUCCESS_MESSAGE);
          markModularObjectUnread(objectCardData?.id);
        },
        onError: () => addToastError('Failed to update attribute, please try again'),
      });
    },
    [buildUpdateObjectDiffPayloadForNewCustomAttribute, objectCardData?.id, updateAttribute],
  );

  return (
    <div className='flex-1'>
      <p className='mb-4 font-bold effra-16'>Enter Field Information</p>
      <p className='mb-8 text-gray-400'>
        Custom Fields will only appear in this item. Once created they can&apos;t be edited only deleted.
      </p>
      <form
        className='flex flex-col gap-4'
        onSubmit={e => {
          e.preventDefault();

          const formData = new FormData(e.target as HTMLFormElement);

          const customField = generateCustomFieldObject({
            type: selectedItem.value,
            label: formData.get('label'),
            prefix: formData.get('prefix') as string,
            suffix: formData.get('suffix') as string,
          });
          const newFieldName = Object.keys(customField)[0];

          void addCustomField({ newFieldValue: customField[newFieldName], attributeName: newFieldName });
          setValue(customField);

          closeModal();
        }}
      >
        <div className='flex flex-col gap-4 h-[55px]'>
          <label className='label'>Field Type</label>
          <Listbox
            value={selectedItem}
            onChange={v => {
              setSelectedItem(v);
            }}
          >
            <Listbox.Button className='listbox-button'>
              <span className='block uppercase truncate effra-xs'>{selectedItem?.label}</span>
              <SelectIcon />
            </Listbox.Button>
            <Transition
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='w-[90.5%] -mt-1 bg-white listbox-option-list'>
                {CUSTOM_FIELD_OPTIONS.map((option) => (
                  <Listbox.Option key={option.value} value={option} as={Fragment}>
                    {({ active }) => (
                      <li className={cx('listbox-option hover:bg-gray-200', { 'active-listbox-option': active })}>
                        {option.label}
                      </li>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </Listbox>
        </div>
        <div className='flex flex-col gap-4'>
          <label htmlFor='label' className='label'>Field Label</label>
          <input type='text' name='label' className='input-text' placeholder='Enter field label' />
        </div>
        <div className='flex flex-col gap-4'>
          <label htmlFor='prefix' className='label'>Prefix</label>
          <input type='text' name='prefix' className='input-text' placeholder='Enter unit' />
        </div>
        <div className='flex flex-col gap-4'>
          <label htmlFor='suffix' className='label'>Suffix / Unit</label>
          <input type='text' name='suffix' className='input-text' placeholder='Enter unit' />
        </div>
        <button className='mt-4 w-full btn-primary'>Add Field</button>
      </form>
    </div>
  );
}
