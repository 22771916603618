import type { ModularObject } from '@/__generated__/types';
import { getListActions } from '@/components/common/Drivers/DriverSection/DriverListActions/getListActions';
import type { AdditionalObjectProps, ScheduleModularObject } from '@/components/Schedule/constants';
import type { ScheduleModularObjectFragment } from '@/graphql/fragments/modularObject.generated';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { TemplateType } from '@/models/template.model';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { cloneDeep } from 'lodash';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

export interface SelectedObjectState {
  drivers: Record<string, ModularObject>;
  builds: Record<string, ModularObject>;
}

type SelectedObjectStateObjectTypes = 'drivers' | 'builds';

const INITIAL_SELECTED_OBJECT_STATE = {
  drivers: {},
  builds: {},
};
interface ListActionContextType {
  selectedAction: string;
  selectedObjects: SelectedObjectState;
  setSelectedAction: (action: string) => void;
  setDefaultSelectedObjects: () => void;
  selectObject: (
    object: ModularObject,
    event,
  ) => void;
  unselectObject: (
    object: ModularObject,
    objectType: SelectedObjectStateObjectTypes,
  ) => void;
  setSelectedObjects: (objects: SelectedObjectState) => void;
  clearSelectedObjects: () => void;
  hardwareId?: string;
  listActions: Record<string, { drivers: boolean; builds: boolean }>;
  unregisterObjectChildren?: (parentId: string, objectId: string) => void;
}

interface ListActionProviderProps {
  hardwareId?: string;
  children: React.ReactNode;
  objectList?: Array<ScheduleModularObject | ModularObject | ScheduleModularObjectFragment & AdditionalObjectProps>;
  flatObjectList?: Set<ScheduleModularObjectFragment>;
  isBuildExpanded?: (objectId: string) => boolean;
  getChildren?: (objectId: string) => ModularObject[];
  unregisterObjectChildren?: (parentId: string, objectId: string) => void;
}

const ListActionContext = createContext<ListActionContextType>(null);

export function useListActionContext (): Readonly<ListActionContextType> {
  const context = useContext(ListActionContext);

  if (context === undefined) {
    throw new Error('useListActionContext must be used within a ListActionProvider');
  }

  return context;
}

export default function ListActionProvider ({
  hardwareId,
  children,
  flatObjectList,
  isBuildExpanded,
  getChildren,
  unregisterObjectChildren,
}: Readonly<ListActionProviderProps>): JSX.Element {
  const [selectedAction, setSelectedAction] = useState<string>(null);
  const [selectedObjects, setSelectedObjects] = useState<SelectedObjectState>(INITIAL_SELECTED_OBJECT_STATE);
  const [lastSelectedId, setLastSelectedId] = useState<string>(null);

  const loggedInUser = useLoggedInUser();
  const resourceCostUnit = loggedInUser?.organization?.resourceCostUnit || 'resource cost';
  const { duplicateListAction: canDuplicate, addToParentListAction: canAddToParent } = useFlags();
  const listActions = getListActions(resourceCostUnit, canDuplicate, canAddToParent);
  const { shiftClickSelect } = useFlags();

  const setDefaultSelectedObjects = useCallback(() => {
    setSelectedObjects({
      drivers: {},
      builds: {},
    });
  }, []);

  const getSelectableChildren = useCallback((objectId: string): ModularObject[] => {
    const children = [];
    const isExpanded = isBuildExpanded(objectId);
    const objectChildren = getChildren(objectId);
    if (objectChildren?.length && isExpanded) {
      objectChildren.forEach((child) => {
        if (child.permission !== 'VIEWER') {
          children.push(child);
        }
        children.push(...getSelectableChildren(child?.id));
      });
    }
    return children;
  }, [isBuildExpanded, getChildren]);

  const selectObject = useCallback((
    object: ScheduleModularObjectFragment,
    event,
  ): void => {
    const objectsToSelect = cloneDeep(selectedObjects);

    if (object?.template?.type === TemplateType.Driver) {
      objectsToSelect.drivers[object.id] = object as ModularObject;
    } else {
      objectsToSelect.builds[object.id] = object as ModularObject;
    }

    const isMultiselect = shiftClickSelect && event?.shiftKey;

    if (isMultiselect && lastSelectedId !== null) {
      // Note: The selected object can come before or after the last selected object
      // Find which object comes first in the list
      const flatObjectListArray = Array.from(flatObjectList);
      const lastSelectedIndex = flatObjectListArray.findIndex((obj) => obj.id === lastSelectedId);
      const selectedIndex = flatObjectListArray.findIndex((obj) => obj.id === object.id);

      const startIndex = Math.min(lastSelectedIndex, selectedIndex);
      const endIndex = Math.max(lastSelectedIndex, selectedIndex);

      for (let i = startIndex; i <= endIndex; i++) {
        const obj = flatObjectListArray[i];

        if (obj?.permission !== 'VIEWER') {
          const objType = obj?.template?.type === TemplateType.Driver ? 'drivers' : 'builds';
          objectsToSelect[objType][obj.id] = obj as ModularObject;
        }
      }
    }

    setSelectedObjects(objectsToSelect);
    if (shiftClickSelect && object?.id) {
      setLastSelectedId(object.id);
    }
  }, [
    selectedObjects,
    shiftClickSelect,
    lastSelectedId,
    flatObjectList,
  ]);

  const unselectObject = useCallback((
    object: ModularObject,
    objectType: SelectedObjectStateObjectTypes,
  ): void => {
    // TODO: Multiselect unselect
    // const objectsToUnselect = [object];
    // setLastSelected([index, depth]);
    // if (isMultiselect && lastSelectedIndex !== null) {
    // }

    setSelectedObjects((prev) => {
      const { [object.id]: _, ...rest } = prev[objectType];
      return {
        ...prev,
        [objectType]: rest,
      };
    });
  }, []);

  const clearSelectedObjects = useCallback((): void => {
    setSelectedObjects(INITIAL_SELECTED_OBJECT_STATE);
  }, []);

  // Check if the selected action is supported for the selected types
  // and handle updating the selected action if the selected action is not supported
  useEffect(() => {
    // Get the selected types
    const selectedTypes = Object.entries(selectedObjects)
      .map(([type, objects]) => {
        if (Object.keys(objects).length > 0) {
          return type;
        }
        return null;
      })
      .filter(Boolean);

    // Check if the selected action is supported for the selected types
    const isNotSupported = selectedAction ? selectedTypes.some((type) => !listActions[selectedAction][type]) : true;

    // If the selected action is not supported, clear the selected action
    if (isNotSupported) {
      setSelectedAction(null);
    }
  }, [selectedObjects]);

  const value = useMemo(
    () => ({
      hardwareId,
      selectedAction,
      selectedObjects,
      setSelectedAction,
      setDefaultSelectedObjects,
      selectObject,
      unselectObject,
      setSelectedObjects,
      clearSelectedObjects,
      listActions,
      unregisterObjectChildren,
    }),
    [
      hardwareId,
      selectedAction,
      selectedObjects,
      setSelectedAction,
      setDefaultSelectedObjects,
      selectObject,
      unselectObject,
      setSelectedObjects,
      clearSelectedObjects,
      listActions,
      unregisterObjectChildren,
    ],
  );
  return <ListActionContext.Provider value={value}>{children}</ListActionContext.Provider>;
}
