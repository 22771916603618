import HeaderName from '@/components/common/Drivers/DriverSection/HeaderName';
import {
  type ChangeEvent,
  createRef,
  type MutableRefObject,
  type Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MegaModalEmptyList from '../../MegaModal/MegaModalEmptyList/MegaModalEmptyList';
import MegaModalHeader from '../../MegaModal/MegaModalHeader/MegaModalHeader';

import { Input } from '@/components/form/Input';
import MagnifyingGlassIcon from '@/components/Icons/MagnifyingGlassIcon';
import type { StepContentProps } from '@/components/Stepper/Stepper';
import { useDebounce } from '@/hooks/useDebounce';
import metrics from '@/util/metrics';
import cx from 'classnames';
import ObjectListRow from '../../AddNewObjectModal/ObjectListRow/ObjectListRow';
import { useGetModularObjectNameQuery } from './getModularObjectName.generated';
import { useGetModularObjectsForDependencySelectionQuery } from './getModularObjectsForDependencySelection.generated';

interface DependencySelectionScreenProps extends StepContentProps {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  originalSelectedIdsRef: MutableRefObject<string[]>;
  preselectedId?: string;
  templateId?: string;
  templateName?: string;
  currentObjectId: string;
  isBlockingDependency: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showModal: (content: JSX.Element, options: any) => void;
}

export default function DependencySelectionScreen ({
  incrementStep,
  selectedIds,
  setSelectedIds,
  originalSelectedIdsRef,
  preselectedId,
  templateId,
  templateName,
  currentObjectId,
  isBlockingDependency,
}: Readonly<DependencySelectionScreenProps>) {
  const inputRef: Ref<HTMLInputElement> = createRef();
  const tableRef = useRef<HTMLDivElement>(null);
  const PAGE_SIZE = 13;

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const { data: currentObject } = useGetModularObjectNameQuery({
    variables: { id: currentObjectId },
    skip: !currentObjectId,
  });
  const { data: _data, loading: isLoadingResults, fetchMore, previousData } =
    useGetModularObjectsForDependencySelectionQuery({
      variables: {
        input: {
          id: currentObjectId,
          filter: debouncedSearchTerm,
        },
        paginationInput: {
          offset: 0,
          limit: PAGE_SIZE,
          direction: 'FORWARD',
          time: null,
        },
      },
      fetchPolicy: 'network-only',
    });

  const data = isLoadingResults ? previousData : _data;

  const hasNextPage = useMemo(
    () => data?.getDependencyCandidatesForObjectId?.pageInfo.hasNextPage,
    [data],
  );

  const [currentOffset, setCurrentOffset] = useState(0);

  const getNextPage = useCallback(async () => {
    if (hasNextPage) {
      const nextOffset = currentOffset + PAGE_SIZE;
      await fetchMore({
        variables: {
          input: {
            id: currentObjectId,
            filter: debouncedSearchTerm,
          },
          paginationInput: {
            offset: nextOffset,
            limit: PAGE_SIZE,
            direction: 'FORWARD',
            time: null,
          },
        },
      });
      setCurrentOffset(nextOffset);
    }
  }, [fetchMore, hasNextPage, currentOffset, debouncedSearchTerm, setCurrentOffset, currentObjectId]);

  useEffect(() => {
    const handleScroll = (e) => {
      if (
        tableRef.current.scrollHeight - e.target.scrollTop - tableRef.current.offsetHeight < 50 &&
        hasNextPage
      ) {
        getNextPage();
      }
    };

    if (tableRef.current) {
      tableRef.current.addEventListener('scroll', handleScroll);

      return () => {
        if (tableRef.current) {
          tableRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [tableRef, getNextPage, hasNextPage]);

  const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(() => {
    const tableWrapper = tableRef.current;
    if (tableWrapper?.scrollHeight > tableWrapper?.clientHeight) {
      setHasScrollbar(true);
    } else {
      setHasScrollbar(false);
    }
  }, []);

  const handleIncrementStep = useCallback(() => {
    metrics.track('dependency modal - object list', {
      templateId,
      isBlockingDependency,
      amountSelected: selectedIds.length,
      amountOriginallySelected: originalSelectedIdsRef.current.length,
      template: templateName,
    });
    incrementStep();
  }, [incrementStep, isBlockingDependency, selectedIds.length, templateId, templateName]);

  const resultList = useMemo(() => {
    if (!data?.getDependencyCandidatesForObjectId.edges) return [];

    if (preselectedId) {
      return data.getDependencyCandidatesForObjectId.edges.sort((a, b) =>
        (a.id === preselectedId) ? -1 : b.id === preselectedId ? 1 : 0
      );
    }

    return data.getDependencyCandidatesForObjectId.edges;
  }, [data?.getDependencyCandidatesForObjectId, preselectedId]);

  const filteredResultList = useMemo(() => {
    return resultList.filter((result) => {
      return result.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
        result.parent?.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
    });
  }, [resultList, debouncedSearchTerm, selectedIds]);

  const modalTitleCTA = isBlockingDependency
    ? 'Add items blocked by '
    : 'Add items blocking ';

  const modalTitle = modalTitleCTA + currentObject?.getModularObjectByID.name;

  const handleCheckAll = useCallback((event): void => {
    const { checked } = event.target;
    if (checked) {
      const allObjectIds = resultList.map((item) => item.id);
      setSelectedIds(allObjectIds);
    } else {
      setSelectedIds([]);
    }
  }, [resultList, setSelectedIds]);

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  return (
    <>
      <MegaModalHeader title={modalTitle} />
      <div className='flex flex-col min-h-0 gap-[24px] grow'>
        <div className='flex gap-4 pb-1 border-b border-black border-solid shrink'>
          <div className='flex items-center'>
            <MagnifyingGlassIcon />
          </div>
          <div className='grow'>
            <Input
              className='flex-1 text-lg placeholder-gray-400 text-black outline-none'
              ref={inputRef}
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder='Search'
            />
          </div>
        </div>
        <div className='w-full overflow-x-auto'>
          <div className='flex flex-col gap-2 min-h-0 grow h-[551px] w-[720px]'>
            <div
              className={cx('flex flex-row', {
                'pr-5': hasScrollbar,
              })}
            >
              {!resultList.length
                ? <div className='text-xs tracking-widest text-gray-400 uppercase'>Results</div>
                : (
                  <>
                    <div className='flex flex-row items-center min-w-0 flex-[2] gap-[8px]'>
                      <div className='flex flex-[1] gap-[8px]'>
                        <HeaderName
                          className='flex shrink'
                          text={
                            <input
                              type='checkbox'
                              onChange={handleCheckAll}
                              className='input-checkbox custom-checkbox checked:bg-primary'
                            />
                          }
                        />
                        <div className='flex-1 font-bold leading-[16px] pl-[3px]'>Name</div>
                      </div>
                      <div className='font-bold flex-[0.75] leading-[16px]'>Parent</div>
                    </div>
                    <div className='flex flex-1 min-w-0 gap-[8px]'>
                      <div className='flex-[0.5] font-bold leading-[16px]'>Permission</div>
                      <div className='font-bold flex-[0.15] leading-[16px]'>Owner</div>
                    </div>
                  </>
                )}
            </div>
            {!resultList?.length ?
              (
                <MegaModalEmptyList
                  message='No items match your search'
                  showButton={false}
                />
              )
              : (
                <div className='flex overflow-y-auto flex-col flex-1' ref={tableRef} data-testid='add-new-object-list'>
                  <div className='flex flex-col gap-[16px]'>
                    {filteredResultList.filter(result => originalSelectedIdsRef.current.includes(result.id)).map(
                      (result) => {
                        return (
                          <ObjectListRow
                            key={`add-new-object-list-row-${result.id}`}
                            object={result}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const newIds = e.target.checked
                                ? [...selectedIds, e.target.value]
                                : [...selectedIds.filter(id => id !== e.target.value)];

                              setSelectedIds(newIds);
                            }}
                            isSelected
                            isDisabled
                          />
                        );
                      },
                    )}
                    {filteredResultList.filter(result => !originalSelectedIdsRef.current.includes(result.id)).map(
                      (result) => {
                        const isSelected = selectedIds.includes(result.id);

                        return (
                          <ObjectListRow
                            key={`add-new-object-list-row-${result.id}`}
                            object={result}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const newIds = e.target.checked
                                ? [...selectedIds, e.target.value]
                                : [...selectedIds.filter(id => id !== e.target.value)];

                              setSelectedIds(newIds);
                            }}
                            isSelected={isSelected}
                            isDisabled={false}
                          />
                        );
                      },
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className='flex gap-8'>
        {/* ENG-5000: Hide until start/target date are available on object creation again */}
        {
          /* <button
          className='w-full btn-ghost h-[36px]'
          onClick={() => {
            showModal(
              <CreateNewObjectModal
                createdFromId={currentObjectId}
                createdFromTemplateId={templateId}
                isBlockingDependency={isBlockingDependency}
                isDependency
              />,
              {
                isMegaModal: true,
                showCloseIcon: false,
              },
            );
          }}
        >
          new item
        </button> */
        }
        <button
          disabled={selectedIds?.length === 0}
          onClick={handleIncrementStep}
          className='w-full btn-primary h-[36px]'
        >
          continue
        </button>
      </div>
    </>
  );
}
