import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, type IconDefinition } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';

export interface AlertProps {
  children?: React.ReactNode;
  icon?: IconDefinition;
  className?: string;
  iconPosition?: 'left' | 'top';
}

export default function Alert({ children, icon = faPlus, className, iconPosition = 'left' }: AlertProps): JSX.Element {
  return (
    <div className={cx('flex items-center w-auto bg-alluringLight shrink gap-[8px] py-[4px] px-[6px]', className)}>
      <FontAwesomeIcon icon={icon} className={iconPosition === 'left' ? '' : 'mt-[4px]'} />
      <span>
        {children}
      </span>
    </div>
  );
}
