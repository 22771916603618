import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetOverlapConversionDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetOverlapConversionDataQuery = { __typename?: 'Query', getDependencyOverlapConversionObjects: Array<{ __typename?: 'DependencyOverlapConversion', id: string | null, objectName: string | null, dismissedByUser: boolean | null, objectStartDate: any | null, objectTargetDate: any | null }> };


export const GetOverlapConversionDataDocument = gql`
    query getOverlapConversionData {
  getDependencyOverlapConversionObjects {
    id
    objectName
    dismissedByUser
    objectStartDate
    objectTargetDate
  }
}
    `;

/**
 * __useGetOverlapConversionDataQuery__
 *
 * To run a query within a React component, call `useGetOverlapConversionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverlapConversionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverlapConversionDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOverlapConversionDataQuery(baseOptions?: Apollo.QueryHookOptions<GetOverlapConversionDataQuery, GetOverlapConversionDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOverlapConversionDataQuery, GetOverlapConversionDataQueryVariables>(GetOverlapConversionDataDocument, options);
      }
export function useGetOverlapConversionDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOverlapConversionDataQuery, GetOverlapConversionDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOverlapConversionDataQuery, GetOverlapConversionDataQueryVariables>(GetOverlapConversionDataDocument, options);
        }
export function useGetOverlapConversionDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOverlapConversionDataQuery, GetOverlapConversionDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOverlapConversionDataQuery, GetOverlapConversionDataQueryVariables>(GetOverlapConversionDataDocument, options);
        }
export type GetOverlapConversionDataQueryHookResult = ReturnType<typeof useGetOverlapConversionDataQuery>;
export type GetOverlapConversionDataLazyQueryHookResult = ReturnType<typeof useGetOverlapConversionDataLazyQuery>;
export type GetOverlapConversionDataSuspenseQueryHookResult = ReturnType<typeof useGetOverlapConversionDataSuspenseQuery>;
export type GetOverlapConversionDataQueryResult = Apollo.QueryResult<GetOverlapConversionDataQuery, GetOverlapConversionDataQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetOverlapConversionDataQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ getDependencyOverlapConversionObjects })
 *   )
 * })
 */
export const mockGetOverlapConversionDataQuery = (resolver: Parameters<typeof graphql.query<GetOverlapConversionDataQuery, GetOverlapConversionDataQueryVariables>>[1]) =>
  graphql.query<GetOverlapConversionDataQuery, GetOverlapConversionDataQueryVariables>(
    'getOverlapConversionData',
    resolver
  )
