import cx from 'classnames';
import { type ChangeEvent, type KeyboardEvent, useEffect, useState } from 'react';

import { Input } from '@/components/form/Input';
import Spinner from '@/components/Icons/Spinner';
import DependencyToolTip from '../DependencyToolTip/DependencyToolTip';

export interface DependencyGapInputProps {
  disabled: boolean;
  disabledTooltipText: string;
  isLoading: boolean;
  onChange?: (value: number) => void;
  value?: number;
}

export default function DependencyGapInput ({
  disabled,
  disabledTooltipText,
  isLoading,
  onChange,
  value = 0,
}: DependencyGapInputProps): JSX.Element {
  const [_value, setValue] = useState(value?.toString() || '0');

  useEffect(() => {
    setValue(value?.toString() || '0');
  }, [value]);

  const updateValue = (value: string, broadcastChange: boolean) => {
    setValue(value);

    // If the user has only input a '-' then we don't want to call the onChange function becuase we haven't actually changed the value to anything meaningful (a number)
    // And we don't want to attempt to parse an empty string as a number and receive a NaN
    if (broadcastChange && value !== '-' && value !== '') {
      const numericValue = parseInt(value, 10);
      if (onChange) {
        onChange(numericValue);
      }
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      if (_value.length === 0) {
        updateValue('-1', true);
        return;
      }
      const newValue = _value === '-' ? '-1' : (parseInt(_value) - 1).toString();
      updateValue(newValue, true);
    }
    if (event.key === 'ArrowUp') {
      if (_value.length === 0) {
        setValue('1');
        return;
      }
      const newValue = _value === '-' ? '0' : (parseInt(_value) + 1).toString();
      updateValue(newValue, true);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Test to only allow numbers and '-'
    if (!event.target.value?.match(/^(?:(?:-?(?:[1-9]\d*)?)|0)$/)) {
      return;
    }

    updateValue(event.target.value, true);
  };

  return (
    <div className='flex gap-[6px] items-center'>
      <span className='effra-12 text-[#666666]'>Gap</span>
      {isLoading ? <Spinner /> : (
        <Input
          className={cx('h-[30px] w-[46px] border-[1px] border-solid border-[#B3B3B3] text-center', {
            'cursor-help': disabled,
          })}
          data-testid='gap-days-input-field'
          disabled={disabled}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
          type='text'
          value={_value || ''}
          title={disabled
            ? disabledTooltipText
            : ''}
        />
      )}
      <span className='effra-12 text-[#666666]'>Days</span>
      <DependencyToolTip type='none' />
    </div>
  );
}
