import { addToastError } from '@/components/Toast/utils';
import { ERROR_MESSAGES } from '@/util/constants';

export function constructMessageForToastError(error: string) {
  const { CYCLE_DETECTION, ZERO_GAP_DETECTION } = ERROR_MESSAGES.DEPENDENCIES;
  const constructErrorMessage = (message: string) => `Cannot edit dependency. ${message}`;

  const errorMessages = {
    [CYCLE_DETECTION.MATCHER]: constructErrorMessage(CYCLE_DETECTION.HUMAN_FRIENDLY_MSG),
    [ZERO_GAP_DETECTION.MATCHER]: constructErrorMessage(ZERO_GAP_DETECTION.HUMAN_FRIENDLY_MSG),
  };

  return errorMessages[error] ?? 'Cannot edit dependency. Please try again later.';
}

export function handleDependencyErrors(error) {
  if (error.code === 409) { // Conflict error. Server provides details in the message.
    addToastError(error?.error);
    return;
  }
  addToastError(constructMessageForToastError(error));
}
