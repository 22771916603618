import { unreadModularObjectsVar } from '@/apollo/localVariables';

export function markAllModularObjectsRead () {
  unreadModularObjectsVar({});
}

export function markModularObjectRead (id: string) {
  if (!id) return;

  const nextUnreadModularObjects = {...unreadModularObjectsVar()};
  delete nextUnreadModularObjects[id];
  unreadModularObjectsVar(nextUnreadModularObjects);
}

export function markModularObjectUnread (id: string) {
  if (!id) return;

  unreadModularObjectsVar({
    ...unreadModularObjectsVar(),
    [id]: true,
  });
}

export function markModularObjectsUnread (ids: string[]) {
  const nextUnreadModularObjects = unreadModularObjectsVar();
  ids.forEach((id) => {
    nextUnreadModularObjects[id] = true;
  });
  unreadModularObjectsVar(nextUnreadModularObjects);
}
