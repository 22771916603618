import type * as Types from '../../../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type ShiftDatesMutationVariables = Types.Exact<{
  input: Types.ShiftDatesInput;
}>;


export type ShiftDatesMutation = { __typename?: 'Mutation', shiftDates: Array<{ __typename?: 'ModularObject', id: string, startDate: any | null, targetDate: any | null, duration: { __typename?: 'ModularObjectDuration', startDate: any | null, targetDate: any | null } | null }> };


export const ShiftDatesDocument = gql`
    mutation ShiftDates($input: ShiftDatesInput!) {
  shiftDates(input: $input) {
    id
    startDate
    targetDate
    duration {
      startDate
      targetDate
    }
  }
}
    `;
export type ShiftDatesMutationFn = Apollo.MutationFunction<ShiftDatesMutation, ShiftDatesMutationVariables>;

/**
 * __useShiftDatesMutation__
 *
 * To run a mutation, you first call `useShiftDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftDatesMutation, { data, loading, error }] = useShiftDatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShiftDatesMutation(baseOptions?: Apollo.MutationHookOptions<ShiftDatesMutation, ShiftDatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShiftDatesMutation, ShiftDatesMutationVariables>(ShiftDatesDocument, options);
      }
export type ShiftDatesMutationHookResult = ReturnType<typeof useShiftDatesMutation>;
export type ShiftDatesMutationResult = Apollo.MutationResult<ShiftDatesMutation>;
export type ShiftDatesMutationOptions = Apollo.BaseMutationOptions<ShiftDatesMutation, ShiftDatesMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockShiftDatesMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ shiftDates })
 *   )
 * })
 */
export const mockShiftDatesMutation = (resolver: Parameters<typeof graphql.mutation<ShiftDatesMutation, ShiftDatesMutationVariables>>[1]) =>
  graphql.mutation<ShiftDatesMutation, ShiftDatesMutationVariables>(
    'ShiftDates',
    resolver
  )
