import type { DependencyBlockType } from '@/__generated__/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
import DependencyToolTipPopover from './DependencyToolTipPopover/DependencyToolTipPopover';

export interface DependencyToolTipProps {
  type: DependencyBlockType;
  side?: 'left' | 'right';
  hoverOverride?: boolean;
  className?: string;
}

export default function DependencyToolTip (
  { type, side = 'right', hoverOverride = false, className }: DependencyToolTipProps,
): JSX.Element {
  return (
    <div className='flex justify-center items-center relative group' data-testid='dependency-tooltip'>
      <div className='px-[6px] py-[4px] bg-[#FFF7D9] rounded-[2px] text-[#0D1815] w-fit cursor-help'>
        <FontAwesomeIcon icon={faLightbulb} />
      </div>
      <div
        className={cx(
          'absolute w-[216px] opacity-0 z-[-999] group-hover:opacity-100 group-hover:z-10 transition-opacity duration-300',
          {
            'left-[32px]': side === 'right',
            'right-[32px]': side === 'left',
            'opacity-100 z-10': hoverOverride,
          },
          className,
        )}
      >
        <DependencyToolTipPopover type={type} />
      </div>
    </div>
  );
}
